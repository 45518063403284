import { AbstractControl, ValidationErrors } from '@angular/forms';

export function Phone(control: AbstractControl): ValidationErrors | null {
  const { value } = control;
  if (!value) return null;
  const cleanedPhone = value.replace(/[-_)(+\s]/g, '');
  const regexp = new RegExp(/^7\d{10}$/i);

  return cleanedPhone.match(regexp)
    ? null
    : {
        phoneFormat:
          'Номер телефона должен содержать 11 цифр без пробелов и других знаков. Например: +7 (912) 123-45-67',
      };
}
