<div class="overflow-hidden">
  <searcher-result-count [count]="promotion().length"></searcher-result-count>

  <div class="list">
    <ng-container *ngFor="let promotion of promotion()">
      <div class="h-8 full-width hidden block-xs"></div>
      <a
        [routerLink]="[HelixRoutes.PromotionsSelect, promotion.id]"
        (click)="closeResults()"
        class="list-item grid grid-gutter-8"
      >
        <div class="grid-item grid-12" *ngIf="promotion.title">
          <div class="typography typography-body grey-800">
            {{ promotion.title }}
          </div>
        </div>

        <div class="grid-item grid-12" *ngIf="promotion.description">
          <div
            class="typography typography-body typography-lines-2 grey-500"
            [innerHTML]="promotion.description | clearHtml"
          ></div>
        </div>
        <div class="h-8 full-width hidden block-xs"></div>
      </a>
    </ng-container>
  </div>
</div>
