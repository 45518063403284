<div class="dropdown dropdown-scrollable full-width" [class.mobile]="isMobile">
  <div
    *ngIf="isMobile"
    class="p-16 mb-24 grid grid-nowrap align-items-center h-64 bg-blue-light"
  >
    <input
      #searchInputMobile
      [formControl]="control"
      class="input input-search input-cancel-button full-width mr-16"
      type="search"
      autocomplete="off"
      maxlength="120"
      placeholder="Поиск по сайту"
    />
    <div class="typography typography-medium grey-500" (click)="closeResults()">
      Отмена
    </div>
  </div>
  <div [class.mobile]="isMobile" class="dropdown-header">
    <div class="tabs-nav">
      <ng-container *ngFor="let tab of repository.tabs; let i = index">
        <div
          *ngIf="tab.content?.length"
          [class.tabs-tab-active]="tab.activated"
          (click)="repository.activateTab(i)"
          class="tabs-tab py-12"
        >
          <span class="typography link">{{ tab.title }}</span>
        </div>
      </ng-container>
      <div class="tabs-extra typography typography-body grey-500">
        <span class="typography link hidden-xs" (click)="closeResults()">
          ✕
        </span>
      </div>
    </div>
  </div>

  <div
    class="dropdown-body typography"
    [ngClass]="isMobile ? 'mobile' : 'max-h-320'"
    *ngIf="!data || !repository.tabs.length"
  >
    <div class="centered full-width">
      <svg
        width="61"
        height="60"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M52.666 5.156H8.819A2.273 2.273 0 0 0 6.625 7.5v4.688H54.86V7.5a2.273 2.273 0 0 0-2.193-2.344"
          fill="#B9E3B5"
        />
        <path
          d="M51.26 36.563H7.413a2.274 2.274 0 0 1-2.194-2.344V6.094A2.273 2.273 0 0 1 7.413 3.75h43.846a2.274 2.274 0 0 1 2.194 2.344v28.125a2.273 2.273 0 0 1-2.194 2.343ZM5.219 10.781h48.234"
          stroke="#313335"
          stroke-miterlimit="10"
        />
        <path
          d="M9.906 7.5a.937.937 0 1 1-1.875 0 .937.937 0 0 1 1.875 0Z"
          fill="#fff"
        />
        <path
          d="M8.969 8.438a.937.937 0 1 0 0-1.875.937.937 0 0 0 0 1.875Z"
          stroke="#313335"
          stroke-width=".751"
          stroke-miterlimit="10"
        />
        <path
          d="M14.594 7.5a.938.938 0 1 1-1.875 0 .938.938 0 0 1 1.875 0Z"
          fill="#fff"
        />
        <path
          d="M13.656 8.438a.938.938 0 1 0 0-1.876.938.938 0 0 0 0 1.875Z"
          stroke="#313335"
          stroke-width=".751"
          stroke-miterlimit="10"
        />
        <path
          d="M19.281 7.5a.938.938 0 1 1-1.875 0 .938.938 0 0 1 1.875 0Z"
          fill="#fff"
        />
        <path
          d="M18.344 8.438a.937.937 0 1 0 0-1.875.937.937 0 0 0 0 1.875Z"
          stroke="#313335"
          stroke-width=".751"
          stroke-miterlimit="10"
        />
        <path
          d="M15.063 15.938h-3.75a.937.937 0 0 0-.938.937v3.75c0 .518.42.938.938.938h3.75c.517 0 .937-.42.937-.938v-3.75a.937.937 0 0 0-.938-.938Z"
          stroke="#313335"
          stroke-miterlimit="10"
        />
        <path
          d="M19.75 17.344h12.656M19.75 20.156h22.969"
          stroke="#313335"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M48.813 15v13.514"
          stroke="#313335"
          stroke-width=".751"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M15.063 25.313h-3.75a.937.937 0 0 0-.938.937V30c0 .518.42.938.938.938h3.75c.517 0 .937-.42.937-.938v-3.75a.937.937 0 0 0-.938-.938Z"
          stroke="#313335"
          stroke-miterlimit="10"
        />
        <path
          d="M19.75 26.719h12.656M19.75 29.531h22.969"
          stroke="#313335"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M33.888 41.441c6.07 0 10.992-4.921 10.992-10.992 0-6.07-4.922-10.992-10.992-10.992-6.071 0-10.992 4.921-10.992 10.992 0 6.071 4.92 10.992 10.992 10.992Z"
          fill="#fff"
        />
        <path
          d="M34.825 39.962a8.616 8.616 0 1 0 0-17.231 8.616 8.616 0 0 0 0 17.23Z"
          fill="#F4CE64"
        />
        <path
          d="M27.882 24.23a8.616 8.616 0 1 0 12.01 12.357 8.616 8.616 0 0 0-12.01-12.357"
          stroke="#313335"
          stroke-width=".751"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M26.191 22.5A11.044 11.044 0 1 0 41.58 38.346 11.044 11.044 0 0 0 26.19 22.5v0Zm13.716 14.091a8.616 8.616 0 1 1-12.001-12.366 8.616 8.616 0 0 1 12 12.366Z"
          stroke="#313335"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="m45.142 40.567-1.429 1.388a1.153 1.153 0 0 0-.024 1.63l7.338 7.556a1.153 1.153 0 0 0 1.63.024l1.43-1.388a1.153 1.153 0 0 0 .024-1.63l-7.338-7.556a1.153 1.153 0 0 0-1.63-.024Z"
          fill="#F4CE64"
          stroke="#313335"
          stroke-miterlimit="10"
        />
        <path
          d="m42.522 37.412-1.886 1.832 2.847 2.932 1.887-1.832-2.848-2.932Z"
          fill="#fff"
          stroke="#313335"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>

    <div
      class="typography typography-bold typography-align-center"
      *ngIf="!data"
    >
      Введите интересующий вас вопрос в&nbsp;строку поиска
    </div>

    <ng-container *ngIf="data && !repository.tabs.length">
      <div class="typography typography-bold typography-x-center">
        По вашему запросу ничего не найдено.
      </div>
      <div class="typography typography-x-center pb-44">
        Попробуйте изменить поисковый запрос.
      </div>
    </ng-container>
  </div>

  <div
    class="dropdown-body typography"
    [ngClass]="isMobile ? 'mobile' : 'max-h-320'"
    *ngIf="repository.tab as tab"
  >
    <div [ngSwitch]="tab.id">
      <searcher-result-catalog
        *ngSwitchCase="tabIds.Catalog"
        [data]="tab.content"
        (closeModal)="closeResults()"
      ></searcher-result-catalog>

      <searcher-result-knowledge
        *ngSwitchCase="tabIds.Knowledge"
        [data]="tab.content"
        (closeModal)="closeResults()"
      ></searcher-result-knowledge>

      <searcher-result-promotion
        *ngSwitchCase="tabIds.Promotion"
        [data]="tab.content"
        (closeModal)="closeResults()"
      ></searcher-result-promotion>

      <searcher-result-address
        *ngSwitchCase="tabIds.Address"
        [data]="tab.content"
        (closeModal)="closeResults()"
      ></searcher-result-address>
    </div>
  </div>
</div>
