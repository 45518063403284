<div class="modal-with-timer">
  <div class="grid-item" [ngSwitch]="errorType">
    <div *ngSwitchCase="ModalErrorType.Error" class="icon icon-warning icon-80"></div>
    <div *ngSwitchCase="ModalErrorType.Info" class="icon icon-info icon-80"></div>
    <div *ngSwitchCase="ModalErrorType.Success" class="icon icon-check-mark icon-80"></div>
  </div>
  <div class="typography typography-h1 mt-28 mb-20" [innerHTML]="title"></div>
  <div class="typography typography-h4" [innerHTML]="message"></div>
  <div class="modal-with-timer__timer typography grey-400">Окно закроется через {{time}} секунд</div>
  <img class="modal-with-timer__figures" *ngIf="isActiveImage" [src]="images.figures" alt="">
</div>
