import { DefaultUrlSerializer, UrlTree } from '@angular/router';
import { HelixRoutes } from '@shared/enums/routes';

export class LowerCaseUrlSerializer extends DefaultUrlSerializer {
  parse(url: string): UrlTree {
    if (url.toLowerCase().includes(`/${HelixRoutes.ShortCancelAppointment}/`)) {
      const key = url.split('/').splice(-1);
      return super.parse(`/${HelixRoutes.ShortCancelAppointment}/${key}`);
    }

    if (url.toLowerCase().includes(`/${HelixRoutes.CancelAppointment}?`)) {
      const queryParams = url.split('?').splice(-1);
      return super.parse(`/${HelixRoutes.CancelAppointment}?${queryParams}`);
    }

    return super.parse(url.toLowerCase());
  }
}
