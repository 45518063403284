import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostBinding,
  HostListener,
} from '@angular/core';
import { PlatformService } from '@shared/services/platform.service';

@Directive()
export abstract class Overscroll implements AfterViewInit {
  protected constructor(
    public element: ElementRef,
    public platform: PlatformService
  ) {}

  @HostBinding('class')
  classes = '';

  @HostListener('scroll', ['$event.target'])
  scrollElement(element: EventTarget): void {
    this.detectScrollPosition(element);
  }

  @HostListener('window:resize')
  windowResize(): void {
    this.dispatchEventScroll();
  }

  ngAfterViewInit(): void {
    if (this.platform.isBrowser) {
      this.dispatchEventScroll();
    }
  }

  protected abstract detectScrollPosition(
    eventTarget: EventTarget | null
  ): void;

  protected setClasses(value: string): void {
    this.classes = value;
  }

  private dispatchEventScroll(): void {
    this.element?.nativeElement?.dispatchEvent(new Event('scroll'));
  }
}
