import { Component, Input } from '@angular/core';
import { ModalService } from '@shared/services/modal/modal.service';
import { first } from 'rxjs/operators';
import { CartItemResultStatus } from 'src/app/shared/types/types';
import { AddCartItemService } from '../../services/add-cart-item.service';

@Component({
  selector: 'app-item-in-complex-modal',
  templateUrl: './item-in-complex-modal.component.html',
})
export class ItemInComplexModalComponent {
  @Input() hxid = '';
  @Input() message = '';
  @Input() ymGoalName = '';

  constructor(
    private addCartItemService: AddCartItemService,
    private modalService: ModalService
  ) {}

  addToCart(): void {
    this.addCartItemService
      .addCartItem(this.hxid, this.ymGoalName)
      .pipe(first())
      .subscribe();
  }

  cancel(): void {
    this.modalService.destroy();
  }
}
