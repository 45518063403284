import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { WebApiCities, WebApiFeedback } from '@shared/enums/routes';
import { Observable } from 'rxjs';
import { SetPractitionerCommand } from '@medindex-webapi';
import { formatISO } from 'date-fns';
import { CityResponse } from '@medindex-webapi';

@Injectable()
export class ModalAppointmentDoctorDatasourceService {
  constructor(private http: HttpClient) {}

  getCities(): Observable<CityResponse[]> {
    return this.http.get<CityResponse[]>(WebApiCities.City);
  }

  sendForm(data: SetPractitionerCommand): Observable<any> {
    if (data.date) {
      const stringDate = formatISO(data.date, {}).split('+')[0];
      const req = JSON.parse(JSON.stringify(data));
      req.date = stringDate;
      return this.http.post(WebApiFeedback.Practitioner, req);
    }
    return this.http.post(WebApiFeedback.Practitioner, data);
  }
}
