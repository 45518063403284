import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { SearcherFormService } from './services/searcher-form.service';
import { SearcherRepositoryService } from './services/searcher-repository.service';
import { PageScrollingService } from '@shared/services/page-scrolling';
import { ModalService } from '@shared/services/modal/modal.service';
import { Subject, distinctUntilChanged, takeUntil } from 'rxjs';

/**
 * Компонент ввода поискового запроса и показа результатов поиска.
 */
@Component({
  selector: 'app-helix-searcher',
  templateUrl: './searcher.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearcherComponent implements OnInit, OnDestroy {
  /** Является ли версией для адаптива. */
  @Input() isMobile: boolean | null = false;

  private ngUnsubscribe$ = new Subject<void>();

  constructor(
    public form: SearcherFormService,
    public repository: SearcherRepositoryService,
    private pageScrolling: PageScrollingService,
    private modal: ModalService
  ) {}

  ngOnInit(): void {
    this.form.onChangeControl();
    this.togglePageScrollOnSearchResultsToggled();
  }

  /** Раскрывает панель с результатами поиска. */
  openResult(): void {
    this.repository.isOpenedResults$.next(true);
  }

  /**
   * Скрывает панель с результатами поиска, если кликнули вне поиска, результатов и не по части модалки.
   * @param clickedOn HTML-нода, лежащая не внутри поиска, по которой кликнули.
   */
  clickedOutside(clickedOn?: Node): void {
    if (!this.modal.isPartOfAnyModal(clickedOn)) {
      this.closeDropdown();
    }
  }

  /** Скрывает панель с результатами поиска и очищает выдачу. */
  closeResult(): void {
    this.closeDropdown();
    this.repository.data$.next(null);
  }

  /** Скрывает панель с результатами поиска. */
  closeDropdown(): void {
    this.repository.isOpenedResults$.next(false);
  }

  /**
   * Устанавливает в(ы)ключение скролла всей страницы при переключении состояния показа результатов поиска
   * (только для адаптивной версии).
   */
  private togglePageScrollOnSearchResultsToggled(): void {
    if (this.isMobile) {
      this.repository.isOpenedResults$
        .pipe(distinctUntilChanged(), takeUntil(this.ngUnsubscribe$))
        .subscribe((searchResultsOpened: boolean) => {
          this.toggleScrollIfMobileSearch(searchResultsOpened);
        });
    }
  }

  /**
   * В(ы)ключает скролл всей страницы, если это поиск для мобильной версии.
   * @param shouldHide Нужно скрыть/показать скролл всей страницы.
   */
  private toggleScrollIfMobileSearch(shouldHide?: boolean): void {
    if (this.isMobile) {
      this.pageScrolling.togglePageScroll(shouldHide);
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
    // удостоверяемся в возвращении скролла на место
    this.toggleScrollIfMobileSearch(false);
  }
}
