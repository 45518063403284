import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzResultModule } from 'ng-zorro-antd/result';
import { ServiceUnavailableModalComponent } from './service-unavailable-modal.component';

@NgModule({
  declarations: [ServiceUnavailableModalComponent],
  exports: [ServiceUnavailableModalComponent],
  imports: [CommonModule, NzResultModule],
})
export class ServiceUnavailableModalModule {}
