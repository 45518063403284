<div class="grid grid-gutter-8">
  <div class="grid-item">
    <button
      class="tag tag-m cursor-pointer"
      [class]="
        (repository.countryTab$ | async) === CountryTab.Ru
          ? 'tag-violet'
          : 'tag-white'
      "
      (click)="repository.setTab(CountryTab.Ru)"
    data-testid="russia-button"
    >
      РОССИЯ
    </button>
  </div>

  <div class="grid-item">
    <button
      class="tag tag-m cursor-pointer"
      [class]="
        (repository.countryTab$ | async) === CountryTab.Kz
          ? 'tag-violet'
          : 'tag-white'
      "
      (click)="repository.setTab(CountryTab.Kz)"
      data-testid="kazakhstan-button"
    >
      КАЗАХСТАН
    </button>
  </div>

  <div class="grid-item">
    <a class="tag tag-m tag-white" href="https://helix.by" data-testid="belarus-button">БЕЛАРУСЬ</a>
  </div>
</div>
