import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class PageScrollingService {
  constructor(@Inject(DOCUMENT) private document: Document) {}

  /**
   * В(ы)ключает скролл на всей странице.
   * @param force Принудительно убрать скролл на всей странице (если true) или добавить (если false).
   * Когда не указано значение, инвертирует состояние скролла.
   */
  togglePageScroll(force?: boolean): void {
    const body: HTMLElement | null = this.document.body;
    body?.classList.toggle('overflow-hidden', force);
  }
}
