import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { BehaviorSubject } from 'rxjs';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-modal-success-send',
  templateUrl: './modal-success-send.component.html',
  styleUrls: ['./modal-success-send.component.scss'],
  standalone: true,
  imports: [CommonModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalSuccessSendComponent implements OnInit {
  constructor(private modal: NzModalRef) {}

  @Input() title = 'Ваше сообщение отправлено!';
  @Input() text =
    'Наши менеджеры свяжутся с вами по указанным контактным данным.';

  time$: BehaviorSubject<number> = new BehaviorSubject<number>(5);

  ngOnInit(): void {
    this.timer();
  }

  timer(): void {
    const timer = setInterval((): void => {
      const currentTime: number = this.time$.value - 1;

      if (currentTime < 1) {
        clearInterval(timer);
        this.modal.close();
      } else {
        this.time$.next(currentTime);
      }
    }, 1000);
  }
}
