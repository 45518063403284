<div
  class="grid grid-nowrap grid-xs-reverse align-items-center align-items-xs-start"
  (click)="invalidate()"
>
  <nz-upload
    [nzMultiple]="true"
    [nzAccept]="'.pdf,.png,.jpg,.jpeg,.PDF,.PNG,.JPG,.JPEG'"
    [nzFileType]="'image/png,application/pdf,image/jpeg'"
    [nzShowUploadList]="false"
    [nzBeforeUpload]="beforeUpload"
    [nzSize]="totalMaxSizeKBs"
    [(nzFileList)]="files"
    class="full-width-xs"
    nz-tooltip
    [nzTooltipTitle]="uploadButtonTooltip"
  >
    <div class="upload-file button button-secondary">
      <div
        class="icon icon-file-plus typography typography-h4 green-basic"
      ></div>
      <div class="ml-8 green-basic">Прикрепить файл</div>
    </div>
  </nz-upload>
</div>

<ng-container *ngFor="let file of files">
  <ng-container
    *ngTemplateOutlet="fileToUpload; context: { file: file, isFailed: false }"
  >
  </ng-container>
</ng-container>

<ng-container *ngIf="failedFiles.length">
  <ng-container *ngFor="let file of failedFiles">
    <ng-container
      *ngTemplateOutlet="fileToUpload; context: { file: file, isFailed: true }"
    >
    </ng-container>
  </ng-container>

  <div class="typography typography-body red">
    <div
      data-testid="total-files-size-exceeded-error-message"
      *ngIf="isMaxSizeExceededError"
    >
      Общий размер загружаемых файлов не должен превышать
      {{ totalMaxSizeBytes | fileSizeToText }}
    </div>
    <div
      data-testid="filename-dublicate-error-message"
      *ngIf="isDublicateError"
    >
      Имена файлов не должны содержать дубликатов
    </div>
  </div>
</ng-container>

<ng-template #fileToUpload let-file="file" let-failed="isFailed">
  <div
    class="upload-file-item grid grid-nowrap align-items-center justify-content-between my-12"
    [ngClass]="{ 'upload-file-item__failed': failed }"
  >
    <div
      class="grid-item icon mr-16 typography typography-h2"
      [ngClass]="
        failed ? 'icon-alert-triangle red' : 'icon-file-checked green-basic'
      "
    ></div>
    <div class="upload-file-item__info grid-item grid grid-column">
      <div
        class="upload-file-item__name grid-item typography typography-headline grey-600"
      >
        {{ file.name }}
      </div>
      <div class="grid-item typography grey-300">
        {{ file.size | fileSizeToText }}
      </div>
    </div>
    <div class="grid-item" *ngIf="!failed">
      <a class="typography m-12" (click)="deleteFile(file)">
        <span class="icon icon-cross-1 grey-600"></span>
      </a>
    </div>
  </div>
</ng-template>
