import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { AddressSearchResult } from '@medindex-webapi';
import { TabContent } from '@shared/components/searcher/types/tab-content';
import { SearcherResultCountComponent } from '../searcher-result-count/searcher-result-count.component';
import { UpperFirstLetterPipe } from '@shared/pipes/upper-first-letter.pipe';
import { HelixRoutes } from '@shared/enums/routes';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'searcher-result-address',
  templateUrl: './searcher-result-address.component.html',
  standalone: true,
  imports: [
    CommonModule,
    SearcherResultCountComponent,
    UpperFirstLetterPipe,
    RouterLink,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearcherResultAddressComponent {
  @Input() data: TabContent = [];
  @Output() closeModal = new EventEmitter<void>();

  protected readonly HelixRoutes = HelixRoutes;

  address(): AddressSearchResult[] {
    return this.data as AddressSearchResult[];
  }

  closeResults(): void {
    this.closeModal.emit();
  }
}
