import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { AsyncPipe } from '@angular/common';
import { UserInfoPopoverComponent } from '@shared/modules/header/components/user-info-popover/user-info-popover.component';
import { ProfileResponse } from '@medindex-webapi/lib/web-api-client';
import { YmGoalHeader } from '@shared/modules/header/enums/ym-goal-header.enum';
import { YaReachGoalDirective } from '@shared/directives/ym-reach-goal.directive';

@Component({
  selector: 'app-user-info-button',
  templateUrl: './user-info-button.component.html',
  styleUrls: ['./user-info-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NzPopoverModule,
    AsyncPipe,
    UserInfoPopoverComponent,
    YaReachGoalDirective,
  ],
})
export class UserInfoButtonComponent {
  @Input() userNickName = '';
  @Input() profileInfo: ProfileResponse | null = null;
  @Input() personalAccount: string | null = null;
  @Input() loginLink: string | null = null;
  protected readonly YmGoalHeader = YmGoalHeader;
}
