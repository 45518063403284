<div class="bg-green-10 rounded-s grey-900 p-16">
  <div class="typography typography-headline typography-medium ellipsis nowrap">
    {{ userNickName }}
  </div>

  <div
    class="grid grid-gutter-12 grid-nowrap typography typography-caption"
    *ngIf="userInfo"
  >
    <span class="grid-item nowrap" *ngIf="userInfo.birthDate">
      {{ age }}
    </span>

    <span class="grid-item ellipsis" *ngIf="userInfo.email">
      {{ userInfo.email }}
    </span>
  </div>

  <div class="grid justify-content-between align-items-center mt-12">
    <div class="typography typography-medium" *ngIf="userInfo">
      <span class="icon icon-1.5x icon-pay-points mr-4"></span>
      {{ points }}
    </div>

    <a class="typography typography-medium" [href]="personalAccount + 'datas'">
      Мои данные
    </a>
  </div>
</div>

<div class="grid grid-gutter-16 typography grey-600 pt-24">
  <a class="grid-item grid-12" [href]="personalAccount + 'orders'">
    <span
      class="icon icon-build bg-slime-violet-medium icon-1.5x mr-4 grey-800"
    ></span>
    Мои заказы
  </a>

  <a class="grid-item grid-12" [href]="personalAccount + 'dynamics'">
    <span
      class="icon icon-pie-chart bg-slime-yellow-accent icon-1.5x mr-4 grey-800"
    ></span>
    Динамика
  </a>
</div>

<div class="divider user-info-divider"></div>

<a class="centered typography grey-600" [href]="exitLink">Выйти</a>
