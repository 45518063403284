<div
  class="grid grid-sm-12 justify-content-sm-between grid-gutter-24 align-items-center"
>
  <div class="grid-item typography typography-body typography-bold">
    {{ title }}
  </div>

  <div class="grid-item grid grid-gutter-12">
    <ng-container *ngFor="let visual of visuals">
      <div
        class="grid-item button-min-width-50"
        *ngIf="getFontSizeType(visual)"
      >
        <button
          class="button"
          [style.fontSize]="visual.size"
          [class.button-subtle]="visual.value !== selectedFontSize"
          (click)="switchOnFontSize(visual.value)"
        >
          A
        </button>
      </div>

      <div class="grid-item button-min-width-50" *ngIf="getThemeType(visual)">
        <button
          class="button"
          [style.background]="visual"
          (click)="switchOnTheme(visual)"
        ></button>
      </div>
    </ng-container>
  </div>
</div>
