import { AbstractControl, ValidationErrors } from '@angular/forms';

export function Email(control: AbstractControl): ValidationErrors | null {
  const { value } = control;
  if (!value) return null;
  const regexp = new RegExp(
    // eslint-disable-next-line no-useless-escape
    /^[_A-Za-z0-9-\+\.]+(\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\.[A-Za-z0-9]+)*(\.[A-Za-z]{2,})$/g
  );

  return value.match(regexp)
    ? null
    : { emailFormat: 'E-mail имеет неверный формат' };
}
