import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { MobileAppsLinksComponent } from './mobile-apps-links.component';
import { MobileAppLinkComponent } from './components/mobile-app-link/mobile-app-link.component';

@NgModule({
  declarations: [MobileAppsLinksComponent, MobileAppLinkComponent],
  imports: [CommonModule, NgOptimizedImage],
  exports: [MobileAppsLinksComponent],
})
export class MobileAppsLinksModule {}
