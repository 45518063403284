import { Pipe, PipeTransform } from '@angular/core';
import { doubleSlashRegExp } from '../../constants/double-slash.regexp';

/*
  Заменяет двойной слеш в ссылке на одинарный.
  Пример: http://helix.ru//page => http://helix.ru/page
*/

@Pipe({
  name: 'formatLink',
  standalone: true,
})
export class FormatLinkPipe implements PipeTransform {
  transform(value: string): string {
    return value.replace(doubleSlashRegExp, '$1/');
  }
}
