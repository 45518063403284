import { ChangeDetectionStrategy, Component } from '@angular/core';
import { IFontSize, IStateVI } from './interfaces/visually-impaired.interfaces';
import { VisuallyImpairedService } from '@shared/services/visually-impaired.service';
import { FontSizeVI, ThemeVI } from './enums/visually-impaired.enums';

@Component({
  selector: 'app-visually-impaired',
  templateUrl: './visually-impaired.component.html',
  styleUrls: ['./visually-impaired.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VisuallyImpairedComponent {
  constructor(public visuallyImpairedService: VisuallyImpairedService) {}

  readonly fontSizesSet: IFontSize[] = [
    {
      value: FontSizeVI.Standard,
      size: '16px',
    },
    {
      value: FontSizeVI.Large,
      size: '22px',
    },
    {
      value: FontSizeVI.XLarge,
      size: '25px',
    },
  ];

  readonly themesSet: ThemeVI[] = [ThemeVI.White, ThemeVI.Brown, ThemeVI.Black];

  setStateVI(state: IStateVI): void {
    this.visuallyImpairedService.setState(state);
  }

  switchOffVI(): void {
    this.visuallyImpairedService.switchOffVI();
  }
}
