import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { SearcherResultsRepositoryService } from './services/searcher-results-repository.service';
import { TabIds } from './types/SearcherResultsTabId';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { SearchResponse } from '@medindex-webapi';
import { CommonModule } from '@angular/common';
import { SearcherResultAddressComponent } from './components/searcher-result-address/searcher-result-address.component';
import { SearcherResultCatalogComponent } from './components/searcher-result-catalog/searcher-result-catalog.component';
import { SearcherResultKnowledgeComponent } from './components/searcher-result-knowledge/searcher-result-knowledge.component';
import { SearcherResultPromotionComponent } from './components/searcher-result-promotion/searcher-result-promotion.component';

@Component({
  selector: 'searcher-results',
  templateUrl: './searcher-results.component.html',
  styleUrls: ['./searcher-results.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    SearcherResultAddressComponent,
    SearcherResultCatalogComponent,
    SearcherResultKnowledgeComponent,
    SearcherResultPromotionComponent,
    ReactiveFormsModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearcherResultsComponent implements OnChanges, AfterViewInit {
  constructor(public repository: SearcherResultsRepositoryService) {}

  @Input() data: SearchResponse | null = null;
  @Input() isMobile: boolean | null = false;
  @Input() searchQuery = '';
  @Input() control = new FormControl('');

  @Output() closeModal = new EventEmitter<void>();

  @ViewChild('searchInputMobile') searchInputMobile:
    | ElementRef<HTMLElement>
    | undefined;

  tabIds = TabIds;

  ngAfterViewInit(): void {
    this.searchInputMobile?.nativeElement.focus();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data) {
      this.repository.getTabs(changes.data.currentValue);
    }
  }

  closeResults(): void {
    this.control.patchValue('');
    this.closeModal.emit();
  }
}
