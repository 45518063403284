import {
  CatalogSearchResult,
  KnowledgeBaseSearchResult,
  PromotionSearchResult,
  AddressSearchResult,
} from '@medindex-webapi';
import { ITab } from '@shared/components/searcher/types/tab';
import { TabIds, TabTitles } from '../types/SearcherResultsTabId';

export class TabCatalog implements ITab {
  constructor(
    public content: CatalogSearchResult[] | null,
    public activated = false,
    public id = TabIds.Catalog,
    public title = TabTitles.Catalog
  ) {}
}

export class TabKnowledge implements ITab {
  constructor(
    public content: KnowledgeBaseSearchResult[] | null,
    public activated = false,
    public id = TabIds.Knowledge,
    public title = TabTitles.Knowledge
  ) {}
}

export class TabPromotion implements ITab {
  constructor(
    public content: PromotionSearchResult[] | null,
    public activated = false,
    public id = TabIds.Promotion,
    public title = TabTitles.Promotion
  ) {}
}

export class TabAddress implements ITab {
  constructor(
    public content: AddressSearchResult[] | null,
    public activated = false,
    public id = TabIds.Address,
    public title = TabTitles.Address
  ) {}
}
