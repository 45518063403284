import { Injectable } from '@angular/core';
import { ConfigurationService } from './configuration.service';
import { CityInitializerService } from './cityInitializer.service';
import { Observable } from 'rxjs';

@Injectable()
export class InitializerService {
  constructor(
    private configuration: ConfigurationService,
    private cityInitializer: CityInitializerService
  ) {}

  /** Устанавливает текущий город, смотря на роут и local storage. */
  setCurrentCity(): Observable<string> {
    return this.cityInitializer.appInitializerPart();
  }

  async setConfiguration(): Promise<void> {
    return this.configuration.init();
  }
}
