// this file is automatically generated by static-page-routes-for-prerender.js script
export const PRERENDERED_ROUTES: string[] = [
  '/site/page/53',
  '/site/page/59',
  '/site/page/65',
  '/site/page/86',
  '/site/page/103',
  '/site/page/116',
  '/site/page/39',
  '/site/page/44',
  '/site/page/54',
  '/site/page/55',
  '/site/page/58',
  '/site/page/61',
  '/site/page/63',
  '/site/page/64',
  '/site/page/122',
  '/site/page/140',
  '/site/page/141',
  '/site/page/147',
  '/site/page/148',
  '/site/page/149',
  '/site/page/154',
  '/site/page/159',
  '/site/page/160',
  '/site/page/161',
  '/site/page/162',
  '/site/page/163',
  '/site/page/165',
  '/site/page/176',
  '/site/page/177',
  '/site/page/178',
  '/site/page/179',
];
