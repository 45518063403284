import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { RepositoryService } from 'src/app/shared/core/services/repository.service';
import { PreOrderItemsCountResponse } from 'src/app/shared/types/types';
import { AddCartItemService } from '../../add-cart-item/services/add-cart-item.service';
import { UserInfoService } from '@shared/modules/header/services/user-info.service';

@Injectable()
export class HeaderRepositoryService extends RepositoryService<PreOrderItemsCountResponse> {
  constructor(
    private addCartItemService: AddCartItemService,
    public userInfoService: UserInfoService
  ) {
    super();
    this.loadData();
  }

  count$ = this.addCartItemService.hxids$.pipe(map((hxids) => hxids.length));

  protected loadData(): void {
    this.userInfoService.loadUserInfo();
  }

  protected create(): PreOrderItemsCountResponse {
    return { count: 0 };
  }
}
