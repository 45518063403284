<div
  data-testid="current-city-question"
  class="typography typography-centered typography-headline typography-bold typography-paragraph"
>
  Ваш город {{ storage.cityName$ | async }}?
</div>

<div
  class="grid grid-gutter-20 justify-content-center row row-gutter-20 row-x-center"
>
  <div class="grid-item row-item">
    <button data-testid="confirm-city-button" class="button button-primary button-s" (click)="confirm()">
      Да
    </button>
  </div>

  <div class="grid-item row-item">
    <button data-testid="reject-city-button" class="button button-subtle button-s" (click)="openCitiesModal()">
      Нет
    </button>
  </div>
</div>
