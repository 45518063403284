import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NzUploadFile, NzUploadModule } from 'ng-zorro-antd/upload';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { FileSizeToTextPipe } from '../pipes/file-size-to-text.pipe';

/** Позволяет выбрать файлы для загрузки. */
@Component({
  selector: 'app-write-to-helix-file-upload',
  standalone: true,
  templateUrl: './write-to-helix-file-upload.component.html',
  styleUrls: ['./write-to-helix-file-upload.component.scss'],
  imports: [CommonModule, NzUploadModule, NzToolTipModule, FileSizeToTextPipe],
})
export class WriteToHelixFileUploadComponent {
  protected totalMaxSizeKBs = 0;
  protected totalMaxSizeBytes = 0;
  /**
   * Максимальный суммарный размер файлов для загрузки (в килобайтах).
   * При значении 0 не ограничено.
   */
  @Input() set totalMaxSize(value: number) {
    this.totalMaxSizeKBs = value;
    this.totalMaxSizeBytes = value * 1024;
  }

  /** Оповещает о текущих выбранных для загрузки файлах. */
  @Output() filesChanged = new EventEmitter<NzUploadFile[]>();

  readonly uploadButtonTooltip =
    'Разрешена загрузка следующих типов файлов: pdf, png, jpg, jpeg (общий размер загружаемых файлов не должен превышать 10 МБ)';

  /** Показать ли ошибку о превышении общего лимита на размер файлов. */
  isMaxSizeExceededError = false;
  /** Показать ли ошибку о дублировании имени файла. */
  isDublicateError = false;

  /** Список файлов, выбранных для отправки. */
  protected files: NzUploadFile[] = [];
  /**
   * Список файлов, которые не будут отправлены из-за превышения допустимого размера
   * (для показа ошибки пользователю).
   */
  protected failedFiles: NzUploadFile[] = [];

  /**
   * Сбрасывает ошибки и удаляет файл из очереди на загрузку с оповещением.
   * @param file Файл.
   */
  deleteFile(file: NzUploadFile): void {
    this.invalidate();
    const indToDelete = this.files.findIndex((f) => f == file);
    if (indToDelete !== -1) {
      this.files.splice(indToDelete, 1);
      this.notify();
    }
  }

  /**
   * Добавляет файл в очередь на загрузку и оповещает с новой коллекцией
   * (или в коллекцию файлов, которые не будут загружены, для показа вместе с ошибкой).
   * (обязан быть стрелочной функцией: https://ng.ant.design/components/upload/en)
   * @param file Файл (в либе есть проблемка, для информации https://github.com/NG-ZORRO/ng-zorro-antd/issues/4744).
   * @returns false (для отложенной загрузки по кнопке)
   */
  beforeUpload = (file: NzUploadFile): boolean => {
    if (!this.validate(file)) {
      this.failedFiles.push(file);
      return false;
    }
    this.files = this.files.concat(file);
    this.notify();
    return false;
  };

  /** Сбрасывает данные о не влезших в лимит файлах и скрывает сообщение об ошибке. */
  invalidate(): void {
    this.failedFiles = [];
    this.isMaxSizeExceededError = false;
    this.isDublicateError = false;
  }

  /**
   * Проверяет, не будет ли добавление этого файла в список
   * 1. превышением общего допустимого размера файлов для отправки;
   * 2. дубликатом уже добавленного файла.
   * @param fileToValidate Файл для проверки.
   * @returns true, если файл можно добавить, иначе - false.
   */
  private validate(fileToValidate: NzUploadFile): boolean {
    let isValid = true;
    if (this.totalMaxSizeBytes) {
      const prevTotalSize = this.files.reduce(
        (total, file) => total + (file.size ?? 0),
        0
      );
      if (prevTotalSize + (fileToValidate.size ?? 0) > this.totalMaxSizeBytes) {
        isValid = false;
        this.isMaxSizeExceededError = true;
      }
    }

    if (this.files.map((file) => file.name).includes(fileToValidate.name)) {
      isValid = false;
      this.isDublicateError = true;
    }

    return isValid;
  }

  /** Оповещает об изменении выбранных для отправки файлов. */
  private notify(): void {
    this.filesChanged.emit(this.files);
  }
}
