import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { StorageService } from '@shared/core/services/storage.service';
import { ModalSize } from '@shared/enums/modal-sizes';
import { ChangeCityService } from '@shared/services/changeCity.service';
import { ModalService } from '@shared/services/modal/modal.service';
import { CityComponent } from '../city/city.component';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { take } from 'rxjs';

@Component({
  selector: 'app-modal-suggest-city',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './modal-suggest-city.component.html',
  styleUrls: ['./modal-suggest-city.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalSuggestCityComponent {
  constructor(
    private modal: NzModalRef,
    public storage: StorageService,
    private modalService: ModalService,
    private changeCityService: ChangeCityService
  ) {
    this.modal.afterClose
      .pipe(take(1))
      .subscribe(() => this.changeCityService.setShowSuggestCity(false));
  }

  openCitiesModal(): void {
    this.modalService.destroyAll();
    this.modalService.create({
      nzContent: CityComponent,
      nzStyle: {
        width: ModalSize.BigInfo,
      },
      nzFooter: null,
    });
  }

  confirm(): void {
    this.modalService.destroyAll();
  }
}
