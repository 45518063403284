import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { BehaviorSubject, Subject, finalize, map, takeUntil } from 'rxjs';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { FeedbackClient } from '@medindex-webapi';
import { ButtonModule } from '@medindex';
import { StorageService } from '@shared/core/services/storage.service';
import { ModalService } from '@shared/services/modal/modal.service';
import { ErrorMessages } from '@shared/types/error-messages.enum';
import { CustomValidators } from '@shared/validators/custom-validators';
import { Agreements } from '@shared/constants/agreements';
import { ModalSize } from '@shared/enums/modal-sizes';
import { ModalSuccessSendComponent } from '../modal-success-send/modal-success-send.component';
import { ModalErrorSendComponent } from '../modal-error-send/modal-error-send.component';

/** Модалка с формой для отправки репорта о найденной ошибке на сайте. */
@Component({
  selector: 'app-report-error-modal',
  standalone: true,
  templateUrl: './report-error-modal.component.html',
  styleUrls: ['./report-error-modal.component.scss'],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    ButtonModule,
  ],
  providers: [FeedbackClient],
})
export class ReportErrorModalComponent implements OnInit, OnDestroy {
  /** Находимся ли в процессе отправки отчета об ошибке. */
  isLoadingSubmit$ = new BehaviorSubject<boolean>(false);

  /** Публичный ключ Recaptcha. */
  recaptchaKey$ = this.storage.configuration$.pipe(
    map((config) => config.recaptchaPublicKey)
  );

  /** Описание полей формы. */
  form?: FormGroup;

  readonly Agreements = Agreements;

  private ngUnsubscribe$ = new Subject<void>();

  constructor(
    private storage: StorageService,
    private modalService: ModalService,
    private feedbackClient: FeedbackClient
  ) {}

  ngOnInit() {
    this.recaptchaKey$
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((recaptchaKey: string): void => {
        this.createForm(recaptchaKey);
        this.applyCurrentUrlToForm();
      });
  }

  /** Показывает модалку для ошибки в Recaptcha. */
  onRecaptchaError(): void {
    this.modalService.createError(ErrorMessages.Common);
  }

  /** Отправляет отчет об ошибке и показывает результат отправки. */
  onSubmit(): void {
    if (this.form?.invalid) return;

    const { bugUrl, message, email, recaptcha } = this.form?.value;

    this.isLoadingSubmit$.next(true);

    this.feedbackClient
      // Прод: стоит Validators.required для рекапчи, так что к этому моменту она не пустая
      // Тест: не стоит валидатор для рекапчи, к этому моменту может быть пустой
      .setSiteErrorFeedback(recaptcha ?? '', email, bugUrl, message)
      .pipe(
        finalize(() => this.isLoadingSubmit$.next(false)),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe({
        next: (): void => {
          this.form?.reset();
          this.modalService.destroyAll();

          this.openModalSuccess();
        },
        error: (): void => {
          this.openModalError(ErrorMessages.Common);
        },
      });
  }

  /** Устанавливает текущее значение из адресной строки в поле для адреса страницы с ошибкой. */
  private applyCurrentUrlToForm(): void {
    if (this.form) {
      this.form.patchValue({ bugUrl: window.location.href });
    }
  }

  /**
   * Создает описание полей формы.
   * @param recaptchaKey Публичный ключ Recaptcha.
   */
  private createForm(recaptchaKey: string): void {
    this.form = new FormGroup({
      bugUrl: new FormControl('', Validators.required),
      message: new FormControl('', Validators.required),
      email: new FormControl(null, [
        Validators.required,
        CustomValidators.Email,
      ]),
      accept: new FormControl(false, Validators.requiredTrue),
      recaptcha: new FormControl(
        null,
        recaptchaKey ? Validators.required : null
      ),
    });
  }

  /** Открывает красивую модалку с таймером (5 с) об успешной отправке сообщения об ошибке. */
  private openModalSuccess(): void {
    this.modalService.create({
      nzTitle: '',
      nzContent: ModalSuccessSendComponent,
      nzComponentParams: {
        title: 'Сообщение об ошибке успешно отправлено!',
        text: '',
      },
      nzStyle: {
        width: ModalSize.Form,
      },
      nzClassName: 'modal-with-bg',
      nzFooter: null,
    });
  }

  /**
   * Показывает попап о неудачной отправке сообщения об ошибке.
   * @param message Текст ошибки.
   */
  private openModalError(message: string): void {
    this.modalService.create({
      nzTitle: '',
      nzContent: ModalErrorSendComponent,
      nzComponentParams: {
        message,
      },
      nzStyle: {
        width: ModalSize.Error,
      },
      nzBodyStyle: {
        padding: '80px 28px',
      },
      nzFooter: null,
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}
