import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import figures from '!!file-loader!./images/send-figures.png';
import { ModalErrorType } from '../../enums/modal-error-type';
import { ModalService } from '../../services/modal/modal.service';

@Component({
  selector: 'app-modal-with-timer',
  templateUrl: './modal-with-timer.component.html',
  styleUrls: ['./modal-with-timer.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ModalWithTimerComponent implements OnInit {
  constructor(private modalService: ModalService) {}

  @Input() title = '';
  @Input() message = '';
  @Input() errorType: ModalErrorType = ModalErrorType.Error;
  @Input() isActiveImage = false;

  time = 5;
  images = {
    figures,
  };

  ngOnInit(): void {
    this.timer();
  }

  get ModalErrorType(): typeof ModalErrorType {
    return ModalErrorType;
  }

  timer(): void {
    const timer = setInterval(() => {
      if (this.time < 1) {
        clearInterval(timer);
        this.modalService.destroy();
      } else {
        // eslint-disable-next-line no-plusplus
        this.time--;
      }
    }, 1000);
  }
}
