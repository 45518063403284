import { Meta, Title } from '@angular/platform-browser';
import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { JsonLdSchema } from './schemas/schema';
import { filter, first, map } from 'rxjs';
import { StorageService } from '@shared/core/services/storage.service';

@Injectable()
export class MetaService {
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private titleService: Title,
    private storage: StorageService
  ) {}

  title(title: string): void {
    this.titleService.setTitle(title);
  }

  description(content: string): void {
    const meta = new Meta(this.document);
    const type = { name: 'description' };
    const description = {
      ...type,
      content,
    };
    const selector = new URLSearchParams(type).toString();

    meta.updateTag(description, selector);
  }

  keywords(content: string): void {
    const meta = new Meta(this.document);
    const type = { name: 'keywords' };
    const keywords = {
      ...type,
      content,
    };
    const selector = new URLSearchParams(type).toString();

    meta.updateTag(keywords, selector);
  }

  insertJsonLdSchema(schema: JsonLdSchema): void {
    let script: HTMLScriptElement;
    let shouldAppend = false;
    const currentSchemas = this._getJsonLdSchema().filter(
      (x) => x['@type'] !== schema['@type']
    );
    const newSchemas = [...currentSchemas, schema];

    const scriptClassName = 'structured-data';
    if (this.document.head.getElementsByClassName('structured-data').length) {
      script = this.document.head.getElementsByClassName(
        scriptClassName
      )[0] as HTMLScriptElement;
    } else {
      script = this.document.createElement('script');
      shouldAppend = true;
    }
    script.setAttribute('class', scriptClassName);
    script.type = 'application/ld+json';
    script.text = JSON.stringify(newSchemas);
    if (shouldAppend) {
      this.document.head.appendChild(script);
    }
  }

  setCanonical(relativeUrl: string): void {
    this.storage.configuration$
      .pipe(
        filter((config) => !!config),
        first(),
        map((config) => config.hosts.main)
      )
      .subscribe((mainUrl) => {
        const url = this._createUrl(mainUrl, relativeUrl);
        this._setCanonicalUrl(url);
      });
  }

  private _createUrl(host: string, relativeUrl: string): string {
    // remove slash
    host = host.replace(/(\/)?$/g, '');
    // remove query params and slash
    relativeUrl = relativeUrl.split('?')[0].replace(/(\/)?$/g, '');
    return `${host}${relativeUrl}`;
  }

  private _setCanonicalUrl(url: string): void {
    const head = this.document.head;
    let element = this.document.querySelector(`link[rel='canonical']`) || null;
    if (!element) {
      element = this.document.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }
    element.setAttribute('rel', 'canonical');
    element.setAttribute('href', url);
  }

  private _getJsonLdSchema(): JsonLdSchema[] {
    const script = this.document.head.getElementsByClassName(
      'structured-data'
    )[0] as HTMLScriptElement;

    if (!script) return [];

    const schemas = JSON.parse(script.innerHTML);

    return schemas;
  }
}
