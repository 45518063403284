<div
  nz-popover
  nzPopoverTrigger="click"
  nzPopoverPlacement="bottomRight"
  [nzPopoverContent]="tmpUserInfo"
  [nzPopoverOverlayClassName]="'user-info-popover'"
  ymGoal
  [nameGoal]="YmGoalHeader.UserNickButtonClick"
>
  <div class="hidden-sm">
    <div
      class="link grid grid-nowrap align-items-center typography green-basic"
    >
      <div class="max-w-144 ellipsis nowrap">{{ userNickName }}</div>
      <span class="icon icon-chevron-down ml-4"></span>
    </div>
  </div>

  <div
    class="block-sm hidden user-icon typography typography-h4 typography-align-center"
  >
    {{ userNickName[0] }}
  </div>
</div>

<ng-template #tmpUserInfo>
  <app-user-info-popover
    [userNickName]="userNickName"
    [userInfo]="profileInfo"
    [personalAccount]="personalAccount"
    [loginLink]="loginLink"
  />
</ng-template>
