import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
} from '@angular/core';

@Component({
  selector: 'app-visually-impaired-button',
  templateUrl: './visually-impaired-button.component.html',
  styleUrls: ['./visually-impaired-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class VisuallyImpairedButtonComponent {
  @Output() toggleVI = new EventEmitter<void>();

  toggleVisualImpaired(): void {
    this.toggleVI.emit();
  }
}
