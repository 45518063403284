export enum ThemeVI {
  White = 'white',
  Black = 'black',
  Brown = 'brown',
}

export enum FontSizeVI {
  Standard = 'standard-font-size',
  Large = 'large-font-size',
  XLarge = 'x-large-font-size',
}
