export enum HttpResponseStatuses {
  'NoContent' = 204,
  'MovedPermanently' = 301,
  'BadRequest' = 400,
  'Forbidden' = 403,
  'NotFound' = 404,
  'BusinessLogicError' = 409,
  'InternalServerError' = 500,
  'ServiceUnavailable' = 503,
  'GatewayTimeout' = 504,
}
