/* eslint-disable no-useless-escape */
export const ValidationPatterns = {
  OrderCode1: '^[A-Za-z0-9]{4}-[A-Za-z0-9]{4}-[0-9]{9}$',
  OrderCode2: '^[A-Za-z0-9]{5}-[A-Za-z0-9]{5}-[0-9]{8}$',
  Email:
    '^[_A-Za-z0-9-\\\\+\\.]+(\\\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$',
  Name: /^[^0-9!@#$%^&&*\{{\}}<>\\\/?|=+~]+$/i,
  Phone: '^\\d{11}$',
  Hxid: '^\\d{2}-\\d{3,4}$',
};
