import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {
  IFontSize,
  IStateVI,
} from '../../interfaces/visually-impaired.interfaces';
import { FontSizeVI, ThemeVI } from '../../enums/visually-impaired.enums';

@Component({
  selector: 'app-visually-impaired-layout',
  templateUrl: './visually-impaired-layout.component.html',
  styleUrls: ['./visually-impaired-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VisuallyImpairedLayoutComponent {
  @Input() title = '';
  @Input() visuals: (IFontSize | ThemeVI)[] = [];
  @Input() selectedFontSize: FontSizeVI | undefined = FontSizeVI.Standard;

  @Output() changeState: EventEmitter<IStateVI> = new EventEmitter<IStateVI>();

  getFontSizeType(value: IFontSize | ThemeVI): value is IFontSize {
    return (value as IFontSize).size !== undefined;
  }

  getThemeType(value: IFontSize | ThemeVI): value is ThemeVI {
    return Object.values(ThemeVI).includes(value as ThemeVI);
  }

  switchOnFontSize(fontSize: FontSizeVI): void {
    this.changeState.emit({ fontSize });
  }

  switchOnTheme(theme: ThemeVI): void {
    this.changeState.emit({ theme });
  }
}
