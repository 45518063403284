import { BehaviorSubject } from 'rxjs';

export abstract class RepositoryService<T> {
  protected abstract create(): T;
  protected abstract loadData(): void;

  private _data$ = new BehaviorSubject<T>(this.create());

  get data(): T {
    return this._data$.getValue();
  }

  set data(value) {
    this._data$.next(value);
  }

  get data$(): BehaviorSubject<T> {
    return this._data$;
  }
}
