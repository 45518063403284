import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';

export abstract class FormService<
  T extends UntypedFormGroup | UntypedFormArray | UntypedFormControl
> {
  protected abstract create(): void;

  private _form!: T;

  get form(): T {
    return this._form;
  }

  set form(value) {
    this._form = value;
  }

  getControl(control: AbstractControl | null): UntypedFormControl {
    return control as UntypedFormControl;
  }
}
