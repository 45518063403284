<div class="hidden block-md">
  <div
    class="grid grid-nowrap justify-content-between bg-green-basic shadow-white-plus-top navigation-container"
  >
    <div class="grid grid-nowrap align-items-center full-width navigation-menu">
      <a
        class="grid grid-column navigation-item"
        *ngFor="let item of itemsNavigation"
        [routerLink]="item.link"
      >
        <div class="icon icon-24 mb-4" [class]="item.icon"></div>
        {{ item.title }}
      </a>
    </div>
    <div
      class="grid bg-green-400 centered burger-button"
      (click)="openBurger()"
      data-testid="adaptive-navigation-down-burger"
    >
      <div class="icon icon-28 icon-burger"></div>
    </div>
  </div>
</div>

<app-burger
  [@flyInOut]="animationState"
  (closeBurger)="closeBurger()"
></app-burger>
