import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { StorageService } from '@shared/core/services/storage.service';
import { PlatformService } from '@shared/services/platform.service';
import { first, Subject, switchMap, takeUntil } from 'rxjs';
import { CityRepositoryService } from '../../services/city-repository.service';
import { UpperFirstLetterPipe } from '@shared/pipes/upper-first-letter.pipe';

@Component({
  selector: 'app-city-list',
  standalone: true,
  imports: [CommonModule, UpperFirstLetterPipe],
  templateUrl: './city-list.component.html',
  styleUrls: ['./city-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CityListComponent implements OnInit, OnDestroy, AfterViewInit {
  constructor(
    public repository: CityRepositoryService,
    public storage: StorageService,
    private platformService: PlatformService
  ) {}

  @ViewChildren('cityTag') tagElements:
    | QueryList<ElementRef<HTMLElement>>
    | undefined;
  @ViewChild('citiesListContainer') citiesListContainer:
    | ElementRef<HTMLElement>
    | undefined;

  private observer: IntersectionObserver | null = null;
  readonly partialTagId = 'cityTag_';

  private ngUnsubscribe$ = new Subject<void>();

  ngOnInit(): void {
    this._scrollToSubject();
  }

  ngAfterViewInit(): void {
    this._initViewObserver();
  }

  private _scrollToSubject(): void {
    this.repository.scrollEvent$
      .pipe(
        switchMap(() => this.repository.currentTag$.pipe(first())),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe((tag: string): void => {
        const element: ElementRef<HTMLElement> | null =
          this.tagElements?.find(
            (el: ElementRef<HTMLElement>): boolean =>
              el.nativeElement.id === this.partialTagId + tag
          ) || null;

        if (element) {
          element.nativeElement.scrollIntoView({
            block: 'start',
            inline: 'nearest',
          });
        }
      });
  }

  private _initViewObserver(): void {
    if (this.platformService.isServer) return;

    const options: IntersectionObserverInit = {
      threshold: 0,
      rootMargin: '0px 0px -99%',
      root: this.citiesListContainer?.nativeElement,
    };

    this.observer = new IntersectionObserver(
      (entries: IntersectionObserverEntry[]): void => {
        entries.forEach((entry: IntersectionObserverEntry): void => {
          if (entry.isIntersecting) {
            const tag: string = entry.target.id.replace(this.partialTagId, '');
            this.repository.setTag(tag);
          }
        });
      },
      options
    );

    // init observer elements after render
    this.repository.citiesByTag$
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((): void => {
        setTimeout((): void => {
          const tagElements: HTMLElement[] | null =
            this.tagElements?.map(
              (tag: ElementRef<HTMLElement>) => tag.nativeElement
            ) || null;

          tagElements?.forEach((tag: HTMLElement) =>
            this.observer?.observe(tag)
          );
        }, 0);
      });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
    this.observer?.disconnect();
  }
}
