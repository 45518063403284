import { Injectable } from '@angular/core';
import { SearchResponse } from '@medindex-webapi';
import { ITab } from '@shared/components/searcher/types/tab';
import {
  TabAddress,
  TabCatalog,
  TabKnowledge,
  TabPromotion,
} from '../models/searcher-results-tab.model';
import { TabIds } from '../types/SearcherResultsTabId';

enum SkipKeys {
  IsOrderNumber = 'isOrderNumber',
}

@Injectable()
export class SearcherResultsDatasourceService {
  tabs(data: SearchResponse): ITab[] {
    const tabs: ITab[] = [];
    const hasContentTab = (key: string): boolean => {
      const keyTyped = key as TabIds;
      return Boolean(data[keyTyped]?.length);
    };
    for (const key in data) {
      if (key !== SkipKeys.IsOrderNumber) {
        let tab: ITab | null = null;

        if (hasContentTab(key)) {
          switch (key) {
            case TabIds.Catalog:
              tab = new TabCatalog(data[key]);
              break;
            case TabIds.Knowledge:
              tab = new TabKnowledge(data[key]);
              break;
            case TabIds.Promotion:
              tab = new TabPromotion(data[key]);
              break;
            case TabIds.Address:
              tab = new TabAddress(data[key]);
              break;
            default:
              break;
          }
        }
        if (tab) {
          tabs.push(tab);
        }
      }
    }

    if (tabs.length) {
      tabs[0].activated = true;
    }

    return tabs;
  }
}
