import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { clearHtmlTags } from '@shared/utils/utils';

@Pipe({ name: 'clearHtml' })
export class ClearHtmlPipe implements PipeTransform {
  constructor(private sanitized: DomSanitizer) {}
  transform(value: string): SafeHtml {
    return this.sanitized.bypassSecurityTrustHtml(clearHtmlTags(value));
  }
}
