import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { FormService } from '@shared/core/services/form.service';

@Injectable()
export class CityFormService extends FormService<
  FormGroup<{
    search: FormControl<string | null>;
  }>
> {
  constructor(private formBuilder: FormBuilder) {
    super();
    this.create();
  }

  private readonly defaultState = {
    search: '',
  };

  protected create(): void {
    this.form = this.formBuilder.group({
      search: this.formBuilder.control(this.defaultState.search),
    });
  }

  reset(): void {
    this.form.reset(this.defaultState);
  }
}
