<form [formGroup]="form" *ngIf="form">
  <div class="grid grid-column grid-gutter-28">
    <div class="grid-item">
      <div class="form-item form-item-required">
        <div class="form-item-label typography typography-medium">
          Адрес страницы
        </div>
        <div class="form-item-description typography typography-body">
          Укажите точный адрес страницы, где вы заметили ошибку
        </div>

        <div class="form-item-control">
          <input
            data-testid="bug-url-input"
            class="input"
            type="text"
            name="BugUrl"
            formControlName="bugUrl"
          />
        </div>

        <ng-container
          *ngIf="form.controls.bugUrl.touched && form.controls.bugUrl.errors"
        >
          <div class="typography typography-body red">
            <div
              data-testid="bug-url-required-error"
              *ngIf="form.controls.bugUrl.errors.required"
            >
              Адрес страницы не заполнен
            </div>
          </div>
        </ng-container>
      </div>
    </div>

    <div class="grid-item">
      <div class="grid grid-column grid-gutter-12">
        <div class="grid-item">
          <div class="form-item form-item-required">
            <div class="form-item-label typography typography-medium">
              Описание ошибки
            </div>
            <div class="form-item-description typography typography-body">
              Опишите ошибку как можно более подробно, чтобы мы смогли быстро ее
              исправить
            </div>

            <div class="form-item-control">
              <textarea
                data-testid="message-input"
                name="BugMessage"
                formControlName="message"
                class="input full-width textarea textarea_vertical-resize"
                rows="5"
              >
              </textarea>
            </div>

            <ng-container
              *ngIf="
                form.controls.message.touched && form.controls.message.errors
              "
            >
              <div class="typography typography-body red">
                <div
                  data-testid="message-required-error"
                  *ngIf="form.controls.message.errors.required"
                >
                  Текст сообщения не заполнен
                </div>
              </div>
            </ng-container>
          </div>
        </div>
        <div class="grid-item">
          <div class="alert alert-info alert-primary">
            <div class="alert-content">
              <div class="alert-message">
                При ошибках, связанных с предварительными заказами, убедительная
                просьба указывать список заказанных анализов, а также шаг
                оформления, на котором произошла ошибка.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="grid-item">
      <div class="form-item form-item-required">
        <div class="form-item-label typography typography-medium">E-mail</div>
        <div class="form-item-description typography typography-body">
          Он может понадобиться для связи с вами, чтобы уточнить некоторые
          детали для исправления
        </div>

        <div class="form-item-control">
          <input
            data-testid="email-input"
            class="input"
            type="text"
            formControlName="email"
            name="BugEmail"
            placeholder="name@email.ru"
          />
        </div>
        <ng-container
          *ngIf="form.controls.email.touched && form.controls.email.errors"
        >
          <div class="typography typography-body red">
            <div
              data-testid="email-required-error"
              *ngIf="form.controls.email.errors.required"
            >
              Укажите e-mail
            </div>
            <div
              data-testid="email-format-error"
              *ngIf="form.controls.email.errors.emailFormat"
            >
              E-mail имеет неверный формат
            </div>
          </div>
        </ng-container>
      </div>
    </div>

    <div class="grid-item">
      <div class="divider"></div>
    </div>

    <div class="grid-item">
      <label class="grid grid-gutter-12 grid-nowrap">
        <div class="grid-item">
          <span class="checkbox">
            <input
              data-testid="accept-input"
              type="checkbox"
              class="checkbox-input"
              formControlName="accept"
            />
            <div class="checkbox-mark"></div>
          </span>
        </div>
        <div class="grid-item grid-xs-11 grid-8">
          <span class="typography typography-body">
            Я даю свое согласие на
            <a
              target="_blank"
              class="typography typography-body green-basic typography-underline"
              [href]="Agreements.PersonalDataProcessingAgreement"
            >
              обработку персональных</a
            >
            данных и ознакомлен с
            <a
              target="_blank"
              class="typography typography-body green-basic typography-underline"
              [href]="Agreements.PrivateAgreement"
            >
              Условиями пользовательского соглашения
            </a>
          </span>
        </div>
      </label>
    </div>

    <div class="grid-item" *ngIf="recaptchaKey$ | async as recaptchaKey">
      <re-captcha
        formControlName="recaptcha"
        size="compact"
        badge="inline"
        errorMode="handled"
        [siteKey]="recaptchaKey"
        (error)="onRecaptchaError()"
      >
      </re-captcha>
    </div>

    <div class="grid-item">
      <hlx-button
        class="full-width-xs"
        [isLoading]="!!(isLoadingSubmit$ | async)"
        [disabled]="form.invalid || !!(isLoadingSubmit$ | async)"
        (click)="onSubmit()"
        data-testid="send-error-report-button"
      >
        Отправить сообщение
      </hlx-button>
    </div>
    <div class="h-80"></div>
  </div>
</form>
