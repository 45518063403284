import { Injectable } from '@angular/core';
import { CitiesClient, CityResponse } from '@medindex-webapi';
import { ChangeCityService } from '@shared/services/changeCity.service';
import { ModalService } from '@shared/services/modal/modal.service';
import { BehaviorSubject, combineLatest, first, map, switchMap } from 'rxjs';
import { CountryTab } from '../enums/coutry-tab.enum';
import { Tag } from '../types/tag';
import { CityFormService } from './city-form.service';
import { Country } from '@shared/enums/country.enum';

@Injectable()
export class CityRepositoryService {
  constructor(
    private citiesClient: CitiesClient,
    private formService: CityFormService,
    private modalService: ModalService,
    private changeCityService: ChangeCityService
  ) {
    this.getCities();
  }

  private _cities$ = new BehaviorSubject<CityResponse[]>([]);
  private _countryTab$ = new BehaviorSubject<CountryTab>(CountryTab.Ru);
  private _searchPhrase$ = new BehaviorSubject<string>('');
  private _currentTag$ = new BehaviorSubject<string>('');
  private _scrollEvent$ = new BehaviorSubject<null>(null);
  private _kzCities$ = this._cities$.pipe(
    map((cities: CityResponse[]) =>
      cities.filter(
        (city: CityResponse): boolean => city.countryCode === Country.KZ
      )
    )
  );
  private _ruCities$ = this._cities$.pipe(
    map((cities: CityResponse[]) =>
      cities.filter((city: CityResponse) => city.countryCode === Country.RU)
    )
  );
  private _importantCities$ = combineLatest([
    this._countryTab$,
    this._kzCities$,
    this._ruCities$,
  ]).pipe(
    map(([coutry, kzCities, ruCities]) =>
      coutry === CountryTab.Kz
        ? kzCities.filter((x) => x.isImportant)
        : ruCities.filter((x) => x.isImportant)
    )
  );

  countryTab$ = this._countryTab$.asObservable();
  searchPhrase$ = this._searchPhrase$.asObservable();
  currentTag$ = this._currentTag$.asObservable();
  scrollEvent$ = this._scrollEvent$.asObservable();
  citiesByTag$ = this.countryTab$.pipe(
    switchMap((tab) => {
      switch (tab) {
        case CountryTab.Kz:
          return this._kzCities$;
        case CountryTab.Ru:
        default:
          return this._ruCities$;
      }
    }),
    map((cities) => {
      const allChars = cities
        .map((city) => city.name?.slice(0, 1)?.toUpperCase() ?? '')
        .filter((char, index, chars) => chars.indexOf(char) === index)
        .sort((a, b): number => (a > b ? 1 : -1));

      return allChars.map((char) => {
        return {
          name: char,
          cities: cities
            .filter((city) => city.name?.slice(0, 1)?.toUpperCase() === char)
            .sort((a, b): number =>
              (a.name ?? '').toLowerCase() > (b.name ?? '').toLowerCase()
                ? 1
                : -1
            ),
        } as Tag;
      });
    })
  );

  searchedCities$ = combineLatest([
    this.searchPhrase$,
    this._cities$,
    this._importantCities$,
  ]).pipe(
    map(([searchPhrase, cities, importantCities]) => {
      if (!searchPhrase) return importantCities;

      return cities.filter((city) =>
        this.getConvertedValue(city?.name || '').includes(
          this.getConvertedValue(searchPhrase)
        )
      );
    })
  );

  getCities(): void {
    this.citiesClient
      .getCities()
      .pipe(first())
      .subscribe((cities) => {
        this._cities$.next(cities);
      });
  }

  selectCity(cityId: number): void {
    this.changeCityService
      .changeCity({ id: cityId, name: '', alias: '' })
      .pipe(first())
      .subscribe(() => {
        this.modalService.destroyAll();
      });
  }

  setTab(tab: CountryTab): void {
    this._countryTab$.next(tab);
    this.formService.reset();
  }

  setTag(tag: string): void {
    this._currentTag$.next(tag);
  }

  setScrollEvent(): void {
    this._scrollEvent$.next(null);
  }

  setSearchPhrase(searchPhrase: string): void {
    this._searchPhrase$.next(searchPhrase);
  }

  private getConvertedValue(value: string): string {
    return value.toLowerCase().replace(/ё/gi, 'е');
  }
}
