import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { IConfiguration } from '@shared/types/configuration.type';
import { PlatformService } from '../platform.service';

declare const window: {
  webim: { accountName: string; domain: string; location: string };
};

@Injectable()
export class WebimInitializerService {
  private renderer: Renderer2;

  constructor(
    rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private document: Document,
    private platform: PlatformService
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  /**
   * Добавляет на страницу кнопку и скрипты Webim чата (только на стороне браузера).
   * @param param0 Объект конфигурации.
   */
  initWebim({ webim: { accountName, domain } }: IConfiguration): void {
    if (this.platform.isServer) return;

    try {
      const host = `https://${domain}`;
      const location = 'HELIXRU';
      window.webim = { accountName, domain, location };

      const script = this.renderer.createElement('script');
      this.renderer.setProperty(script, 'type', 'text/javascript');
      this.renderer.setProperty(script, 'src', `${host}/js/button.js`);
      this.renderer.setProperty(script, 'async', true);

      const pixel = this.renderer.createElement('img');
      this.renderer.setProperty(
        pixel,
        'src',
        `${host}/button.php?location=${location}`
      );
      const button = this.renderer.createElement('a');
      this.renderer.setProperty(button, 'className', 'webim_button');
      this.renderer.setProperty(button, 'href', '#');
      this.renderer.setProperty(button, 'rel', 'webim');
      this.renderer.appendChild(button, pixel);

      this.renderer.appendChild(this.document.body, button);
      this.renderer.appendChild(this.document.body, script);
    } catch (error) {
      console.error(error);
    }
  }
}
