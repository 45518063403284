import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalAppointmentDoctorComponent } from './modal-appointment-doctor.component';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IMaskModule } from 'angular-imask';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { ModalAppointmentDoctorDatasourceService } from './services/modal-appointment-doctor-datasource.service';
import { FingerprintService } from '../../services/fingerprint.service';
import { FormatLinkPipe } from '../../pipes/format-link/format-link.pipe';
import { NzModalModule } from 'ng-zorro-antd/modal';

@NgModule({
  declarations: [ModalAppointmentDoctorComponent],
  imports: [
    CommonModule,
    NzDatePickerModule,
    NzTimePickerModule,
    NzSelectModule,
    NzModalModule,
    FormsModule,
    ReactiveFormsModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    IMaskModule,
    FormatLinkPipe,
  ],
  exports: [ModalAppointmentDoctorComponent],
  providers: [ModalAppointmentDoctorDatasourceService, FingerprintService],
})
export class ModalAppointmentDoctorModule {}
