import { Injectable } from '@angular/core';
import { EMPTY, Observable, throwError } from 'rxjs';
import { HttpResponseStatuses } from '../../enums/http-statuses';
import { ErrorService } from './error.service';
import { HttpResponse } from '@angular/common/http';

@Injectable()
export class DatasourceService {
  constructor(private errorService: ErrorService) {}

  errorHandler(error: { [key: string]: any }): Observable<never> {
    const status = error.status || error.StatusCode;

    if (status === HttpResponseStatuses.BusinessLogicError) {
      this.errorService.error$.next(
        error?.error?.Message ?? error?.Message ?? ''
      );
      return EMPTY;
    }
    return throwError(error);
  }

  checkResponseStatus<T>(response: HttpResponse<T>): T | null {
    if (response.status === HttpResponseStatuses.NoContent) {
      return null;
    }
    return response.body;
  }
}
