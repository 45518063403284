import { Injectable } from '@angular/core';
import {
  CitiesClient,
  CityResponse,
  CitiesController_SetCurrentCityRequest,
} from '@medindex-webapi';
import {
  tap,
  Observable,
  filter,
  first,
  of,
  switchMap,
  catchError,
} from 'rxjs';
import { StorageService } from '@shared/core/services/storage.service';
import { ModalService } from './modal/modal.service';
import { ModalSuggestCityComponent } from '@shared/components/modal-suggest-city/modal-suggest-city.component';
import { ModalSize } from '@shared/enums/modal-sizes';
import { PlatformService } from './platform.service';
import { ModalTitle } from '@shared/enums/modal-title.enum';
import { ModalChangeCityWarningComponent } from '@shared/components/modal-change-city-warning/modal-change-city-warning.component';
import { AddCartItemService } from '@shared/modules/add-cart-item/services/add-cart-item.service';
import { DatasourceService } from '@shared/core/services/datasource.service';
import { CityComponent } from '@shared/components/city/city.component';

@Injectable()
export class ChangeCityService {
  constructor(
    private citiesClient: CitiesClient,
    private storage: StorageService,
    private modalService: ModalService,
    private platformService: PlatformService,
    private addCartItemService: AddCartItemService,
    private datasource: DatasourceService
  ) {}

  changeCityWithConfirmation(cityId: number): void {
    this.addCartItemService.hxids$
      .pipe(
        first(),
        tap((hxids) => {
          if (hxids.length) {
            this.modalService.create({
              nzTitle: ModalTitle.Confirmation,
              nzContent: ModalChangeCityWarningComponent,
              nzStyle: {
                width: ModalSize.Warning,
              },
              nzComponentParams: {
                cityId,
              },
              nzFooter: null,
            });
          }
        }),
        filter((hxids) => !hxids.length),
        switchMap(() => this.changeCity({ id: cityId, name: '', alias: '' }))
      )
      .subscribe();
  }

  changeCity(
    request: CitiesController_SetCurrentCityRequest
  ): Observable<CityResponse> {
    return this.citiesClient.setCurrentUserCity(request).pipe(
      tap((currentCity) => {
        this.storage.setCity(currentCity);
      }),
      catchError((error) => this.datasource.errorHandler(error))
    );
  }

  /**
   * Запрашивает с сервера текущий установленный город и применяет его к фронту.
   * @returns Observable с моделью текущего города.
   */
  setCurrentCity(): Observable<CityResponse> {
    return this.citiesClient.getCurrentUserCity(undefined).pipe(
      tap((city) => this.storage.setCity(city)),
      catchError((error) => this.datasource.errorHandler(error))
    );
  }

  showSuggestCityModal(): Observable<string> {
    if (this.platformService.isServer) return of('');

    return this.storage.cityName$.pipe(
      filter((cityName): cityName is string => !!cityName),
      first(),
      tap(() => {
        const cachedLayoutData = this.storage.getLayoutViewDataCache();
        if (cachedLayoutData.showCitySuggest) {
          this.modalService.create({
            nzContent: ModalSuggestCityComponent,
            nzStyle: {
              width: ModalSize.Message,
            },
            nzFooter: null,
            nzClosable: false,
          });
        }
      })
    );
  }

  setShowSuggestCity(suggestCity: boolean): void {
    const cachedLayoutData = this.storage.getLayoutViewDataCache();
    this.storage.setLayoutViewDataCache({
      ...cachedLayoutData,
      showCitySuggest: suggestCity,
    });
  }

  openCityModal(): void {
    this.addCartItemService.hxids$
      .pipe(first())
      .subscribe((hxids: string[]): void => {
        if (hxids.length) {
          this.modalService.create({
            nzTitle: ModalTitle.Confirmation,
            nzContent: ModalChangeCityWarningComponent,
            nzStyle: {
              width: ModalSize.Warning,
            },
            nzFooter: null,
          });
        } else {
          this.modalService.create({
            nzContent: CityComponent,
            nzStyle: {
              width: ModalSize.BigInfo,
            },
            nzFooter: null,
          });
        }
      });
  }
}
