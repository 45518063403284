<div class="typography typography-paragraph">Эта услуга уже заказана</div>
<div class="grid grid-gutter-12">
  <div class="grid-item">
    <button class="button button-primary" (click)="addToCart()">
      Всё равно заказать
    </button>
  </div>
  <div class="grid-item">
    <button class="button button-subtle" (click)="cancel()">Отмена</button>
  </div>
</div>
