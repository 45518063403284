import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { DOCUMENT, isPlatformServer } from '@angular/common';

@Injectable()
export class CookieService {
  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: NonNullable<unknown>
  ) {}

  getCookie(name: string): string | undefined {
    if (isPlatformServer(this.platformId)) return;

    const matches = this.document.cookie.match(
      new RegExp(
        '(?:^|; )' +
          name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') +
          '=([^;]*)'
      )
    );
    return matches ? decodeURIComponent(matches[1]) : undefined;
  }

  setCookie(
    name: string,
    value: string,
    options: { [key: string]: any } = {}
  ): void {
    options = {
      path: '/',
      ...options,
    };

    if (options.expires instanceof Date) {
      options.expires = options.expires.toUTCString();
    }

    let updatedCookie =
      encodeURIComponent(name) + '=' + encodeURIComponent(value);

    for (const optionKey in options) {
      updatedCookie += '; ' + optionKey;
      const optionValue = options[optionKey];
      if (optionValue !== true) {
        updatedCookie += '=' + optionValue;
      }
    }

    this.document.cookie = updatedCookie;
  }

  deleteCookie(name: string): void {
    this.setCookie(name, '', {
      'max-age': -1,
    });
  }

  getCookieFromCookiesString(
    name: string,
    cookies: string | undefined
  ): string | undefined {
    if (!cookies) return;

    let cookie: string | undefined;
    const cookiesArray = cookies.split(';');

    for (let i = 0; i < cookiesArray.length; i++) {
      const cookieFounds = cookiesArray[i].match(
        new RegExp(`^\\s*${name}=(.*)$`)
      );

      if (cookieFounds && cookieFounds[1]) {
        cookie = decodeURIComponent(cookieFounds[1]);
        break;
      }
    }

    return cookie;
  }
}
