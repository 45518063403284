import { Inject, Injectable, OnDestroy, Optional } from '@angular/core';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { Request } from 'express';
import { DOCUMENT } from '@angular/common';
import { BehaviorSubject, filter, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { PlatformService } from '@shared/services/platform.service';
import { CookieService } from '@shared/services/cookie.service';
import { IStateVI } from '../../modules/visually-impaired/interfaces/visually-impaired.interfaces';
import {
  FontSizeVI,
  ThemeVI,
} from '../../modules/visually-impaired/enums/visually-impaired.enums';
import { VisuallyImpairedKey } from 'src/app/modules/visually-impaired/constants/visually-impaired.constants';

@Injectable()
export class VisuallyImpairedService implements OnDestroy {
  constructor(
    private platform: PlatformService,
    private cookieService: CookieService,
    @Optional() @Inject(REQUEST) private req: Request,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.initVI();
    this.handlerChangesState();
  }

  stateVI$ = new BehaviorSubject<IStateVI | null>(null);
  private ngUnsubscribe$ = new Subject<void>();

  private initVI(): void {
    let stateVI: string | undefined;

    if (this.platform.isServer) {
      stateVI = this.cookieService.getCookieFromCookiesString(
        VisuallyImpairedKey,
        this.req?.headers?.cookie
      );
    } else {
      stateVI = this.cookieService.getCookie(VisuallyImpairedKey);
    }

    if (stateVI) {
      const state = JSON.parse(stateVI);
      this.setState(state);
    }
  }

  private handlerChangesState(): void {
    this.stateVI$
      .pipe(
        filter((state: IStateVI | null): state is IStateVI => !!state),
        tap((state: IStateVI): void => {
          this.changeVisuallyImpaired(state);
        }),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe();
  }

  private changeVisuallyImpaired({ theme, fontSize }: IStateVI): void {
    if (this.platform.isBrowser) {
      this.cookieService.setCookie(
        VisuallyImpairedKey,
        JSON.stringify({
          theme,
          fontSize,
        })
      );

      this.document.documentElement.className = `visually-impaired disable-palette disable-effects visually-impaired-${theme} ${fontSize}`;
    }
  }

  setState({ theme, fontSize }: IStateVI): void {
    if (theme && fontSize) {
      this.stateVI$.next({
        theme,
        fontSize,
      });
    } else {
      if (theme) {
        this.stateVI$.next({
          ...this.stateVI$.value,
          theme,
        });
      }

      if (fontSize) {
        this.stateVI$.next({
          ...this.stateVI$.value,
          fontSize,
        });
      }
    }
  }

  switchOnVI(): void {
    this.setState({
      theme: ThemeVI.White,
      fontSize: FontSizeVI.Standard,
    });
  }

  switchOffVI(): void {
    this.cookieService.deleteCookie(VisuallyImpairedKey);
    window.location.reload();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}
