import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-modal-error-send',
  templateUrl: './modal-error-send.component.html',
  styleUrls: ['./modal-error-send.component.scss'],
})
export class ModalErrorSendComponent {
  @Input()
  message = '';
}
