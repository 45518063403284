<div
  class="grid grid-nowrap justify-content-between full-width grid-gutter-12 mb-48"
>
  <div class="grid-item">
    <app-country-select></app-country-select>
  </div>
</div>
<div class="grid grid-gutter-16 grid-column overflow-hidden">
  <div class="grid-item grid-12">
    <hlx-input
      [control]="formService.form.controls.search"
      class="input-search"
      placeholder="Название города"
    >
    </hlx-input>
  </div>
  <div class="grid-item grid-12">
    <app-city-search></app-city-search>
  </div>
  <div
    class="grid-item grid-12"
    *ngIf="(repository.citiesByTag$ | async)?.length"
  >
    <app-city-tags></app-city-tags>
  </div>
  <div
    class="grid-item grid-12"
    *ngIf="(repository.citiesByTag$ | async)?.length"
  >
    <app-city-list></app-city-list>
  </div>
  <div
    class="grid-item grid-12"
    *ngIf="!(repository.citiesByTag$ | async)?.length"
  >
    <nz-skeleton
      [nzActive]="true"
      [nzTitle]="false"
      [nzParagraph]="{ rows: 5 }"
    ></nz-skeleton>
  </div>
</div>
