import { Organization } from './schemas/organization';
import { Product } from './schemas/product';
import { HelixRoutes } from '../../enums/routes';
import { clearHtmlTags } from '../../utils/utils';
import { Article } from './schemas/article';
import {
  KbAnalyseItemResponse,
  KbDiseaseItemResponse,
  KbGeneticDictionaryItemResponse,
  NomenclatureResponse,
} from '@medindex-webapi';
import { BreadcrumbItem } from '@shared/modules/breadcrumb/types/breadcrumbItem';
import { BreadcrumbList } from './schemas/breadcrumbList';

interface Map {
  [key: string]: string;
}

export class JsonLdStorage {
  private static readonly CURRENCY_CODE_MAP: Map = {
    643: 'RUB',
    398: 'KZT',
  };

  static Organization(phone: string): Organization {
    return {
      '@context': 'http://schema.org',
      '@type': 'Organization',
      url: 'https://helix.ru',
      name: 'Лабораторная служба Хеликс',
      alternateName: 'Helix',
      legalName: 'ООО «НПФ «ХЕЛИКС»',
      address: {
        '@type': 'PostalAddress',
        addressCountry: 'RU',
        addressRegion: 'Санкт-Петербург',
        addressLocality: 'Санкт-Петербург',
        streetAddress: 'наб. реки Карповки, 5',
        postalCode: '197022',
      },
      contactPoint: {
        '@type': 'ContactPoint',
        telephone: phone,
        contactType: 'Служба подержки клиентов',
        areaServed: 'RU',
        availableLanguage: {
          '@type': 'Language',
          name: 'Russian',
          alternateName: 'ru',
        },
      },
      email: 'feedback@helix.ru',
      telephone: phone,
      sameAs: [
        'https://vk.com/helix_lab',
        'https://t.me/helixfamily',
        'https://zen.yandex.ru/id/622f2b11c658c87624297943',
      ],
    };
  }

  static Product(nomenclature: NomenclatureResponse): Product {
    return {
      '@context': 'http://schema.org',
      '@type': 'Product',
      name: nomenclature.nomenclatureInfo?.itemTitle ?? '',
      description: clearHtmlTags(
        nomenclature.kbNomenclatureInfo?.shortDescription ?? ''
      ),
      brand: {
        '@type': 'Brand',
        name: 'Лабораторная служба Хеликс',
      },
      offers: {
        '@type': 'Offer',
        url: `/${HelixRoutes.CatalogItem}/${nomenclature.hxid}`,
        price: nomenclature.price,
        priceCurrency:
          this.CURRENCY_CODE_MAP[nomenclature.currency?.code ?? ''] ?? '',
        availability: 'https://schema.org/InStock',
      },
      productID: nomenclature.hxid ?? '',
    };
  }

  static Article(
    kbItem:
      | KbAnalyseItemResponse
      | KbDiseaseItemResponse
      | KbGeneticDictionaryItemResponse
  ): Article {
    let text = '';

    if ('kbInfo' in kbItem) {
      text =
        (kbItem.kbInfo?.shortInfo ?? '') +
        (kbItem.kbInfo?.info ?? '') +
        (kbItem.kbInfo?.interpretationInfo ?? '') +
        (kbItem.kbInfo?.additionalInfo ?? '');
    }

    if ('text' in kbItem) {
      text = kbItem.text ?? '';
    }

    return {
      '@context': 'http://schema.org',
      '@type': 'Article',
      name: kbItem.title ?? '',
      headline: kbItem.title ?? '',
      articleBody: clearHtmlTags(text),
    };
  }

  static BreadcrumbList(breadcrumbs: BreadcrumbItem[]): BreadcrumbList {
    return {
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: breadcrumbs.map((breadcrumb, index) => {
        return {
          '@type': 'ListItem',
          position: index + 1,
          item: breadcrumb.href
            ? `https://helix.ru${breadcrumb.href}`
            : undefined,
          name: breadcrumb.text,
        };
      }),
    };
  }
}
