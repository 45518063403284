import { NgModule } from '@angular/core';
import { SearcherComponent } from './searcher.component';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SearchClient } from '@medindex-webapi';
import { ClickOutsideDirective } from '@shared/directives/click-outside.directive';
import { ClearHtmlPipeModule } from '@shared/pipes/clear-html/clear-html-pipe.module';
import { SearcherResultsComponent } from './components/searcher-results/searcher-results.component';
import { SearcherResultsDatasourceService } from './components/searcher-results/services/searcher-results-datasource.service';
import { SearcherResultsRepositoryService } from './components/searcher-results/services/searcher-results-repository.service';
import { SearcherFormService } from './services/searcher-form.service';
import { SearcherRepositoryService } from './services/searcher-repository.service';

/** Модуль поиска в шапке (shared состояние). */
@NgModule({
  declarations: [SearcherComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    FormsModule,
    ClearHtmlPipeModule,
    SearcherResultsComponent,
    ClickOutsideDirective,
  ],
  providers: [
    SearchClient,
    SearcherFormService,
    SearcherRepositoryService,
    SearcherResultsRepositoryService,
    SearcherResultsDatasourceService,
  ],
  exports: [SearcherComponent],
})
export class SearcherModule {}
