import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HelixRoutes } from '@shared/enums/routes';

const routes: Routes = [
  {
    path: HelixRoutes.Error,
    loadComponent: () =>
      import('./shared/components/not-found/not-found.component').then(
        (component) => component.NotFoundComponent
      ),
  },
  {
    path: `${HelixRoutes.Pages}/46`,
    loadChildren: () =>
      import(
        './modules/licenses-and-certificates/licenses-and-certificates.module'
      ).then((module) => module.LicensesAndCertificatesModule),
  },
  {
    path: `${HelixRoutes.Pages}/52`,
    loadChildren: () =>
      import('./modules/dms/dms.module').then((module) => module.DmsModule),
  },
  {
    path: `${HelixRoutes.Pages}/57`,
    loadChildren: () =>
      import('./modules/legal-information/legal-information.module').then(
        (module) => module.LegalInformationModule
      ),
  },
  {
    path: `${HelixRoutes.Pages}/107`,
    loadComponent: () =>
      import('./modules/contacts/contacts.component').then(
        (component) => component.ContactsComponent
      ),
  },
  {
    path: HelixRoutes.Pages,
    loadChildren: () =>
      import('./modules/pages/pages.module').then(
        (module) => module.PagesModule
      ),
  },
  {
    path: HelixRoutes.Cart,
    loadChildren: () =>
      import('./modules/cart/cart.module').then((module) => module.CartModule),
  },
  {
    path: HelixRoutes.Catalog,
    loadChildren: () =>
      import('./modules/catalog/catalog.module').then(
        (module) => module.CatalogModule
      ),
  },
  {
    path: `:cityAlias/${HelixRoutes.Catalog}`,
    loadChildren: () =>
      import('./modules/catalog/catalog.module').then(
        (module) => module.CatalogModule
      ),
  },
  {
    path: HelixRoutes.HelixExpress,
    loadChildren: () =>
      import('./modules/lab-pass/lab-pass.module').then(
        (module) => module.LabPassModule
      ),
  },
  {
    path: HelixRoutes.Loyalty,
    loadChildren: () =>
      import('./modules/loyalty/loyalty.module').then(
        (module) => module.LoyaltyModule
      ),
  },
  {
    path: HelixRoutes.Promotions,
    loadChildren: () =>
      import('./modules/promotions/promotions.module').then(
        (module) => module.PromotionsModule
      ),
  },
  {
    path: `:cityAlias/${HelixRoutes.Promotions}`,
    loadChildren: () =>
      import('./modules/promotions/promotions.module').then(
        (module) => module.PromotionsModule
      ),
  },
  {
    path: HelixRoutes.Job,
    loadChildren: () =>
      import('./modules/job/job.module').then((module) => module.JobModule),
  },
  {
    path: HelixRoutes.Onco,
    loadChildren: () =>
      import('./modules/onko/onko.module').then((module) => module.OnkoModule),
  },
  {
    path: HelixRoutes.Helixbook,
    loadChildren: () =>
      import('./modules/helix-book/helix-book.module').then(
        (module) => module.HelixBookModule
      ),
  },
  {
    path: HelixRoutes.Technologies,
    loadChildren: () =>
      import('./modules/technologies/technologies.module').then(
        (module) => module.TechnologiesModule
      ),
  },
  {
    path: HelixRoutes.CancelAppointment,
    loadChildren: () =>
      import('./modules/cancel-appointment/cancel-appointment.module').then(
        (module) => module.CancelAppointmentModule
      ),
  },
  {
    path: 'feed',
    redirectTo: HelixRoutes.News,
    pathMatch: 'full',
  },
  {
    path: 'feed',
    loadChildren: () =>
      import('./modules/news/news.module').then((module) => module.NewsModule),
  },
  {
    path: HelixRoutes.Publications,
    loadChildren: () =>
      import('./modules/publications/publications.module').then(
        (module) => module.PublicationsModule
      ),
  },
  {
    path: HelixRoutes.Centers,
    loadChildren: () =>
      import('./modules/centers/centers.module').then(
        (module) => module.CentersModule
      ),
  },
  {
    path: `:cityAlias/${HelixRoutes.Centers}`,
    loadChildren: () =>
      import('./modules/centers/centers.module').then(
        (module) => module.CentersModule
      ),
  },
  {
    path: HelixRoutes.Center,
    loadChildren: () =>
      import('./modules/center/center.module').then(
        (module) => module.CenterModule
      ),
  },
  {
    path: `${HelixRoutes.ShortCancelAppointment}/:key`,
    redirectTo: HelixRoutes.CancelAppointment,
    pathMatch: 'full',
  },
  {
    path: HelixRoutes.Sitemap,
    loadChildren: () =>
      import('./modules/sitemap/sitemap.module').then(
        (module) => module.SitemapModule
      ),
  },
  {
    path: HelixRoutes.MobileService,
    loadChildren: () =>
      import('./modules/ms/ms.module').then((module) => module.MsModule),
  },
  {
    path: `:cityAlias/${HelixRoutes.MobileService}`,
    loadChildren: () =>
      import('./modules/ms/ms.module').then((module) => module.MsModule),
  },
  {
    path: HelixRoutes.Home,
    loadChildren: () =>
      import('./modules/home/home.module').then((module) => module.HomeModule),
  },
  {
    path: HelixRoutes.Site,
    redirectTo: HelixRoutes.Home,
  },
  {
    path: `:cityAlias/`,
    loadChildren: () =>
      import('./modules/home/home.module').then((module) => module.HomeModule),
  },
  {
    path: `:cityAlias`,
    loadChildren: () =>
      import('./modules/home/home.module').then((module) => module.HomeModule),
  },
  {
    path: '**',
    redirectTo: HelixRoutes.Error,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
