import { Injectable } from '@angular/core';
import { ModalService } from '@shared/services/modal/modal.service';
import { WriteToHelixModalComponent } from '../write-to-helix-modal.component';
import { ModalSize } from '@shared/enums/modal-sizes';
import { UserFeedbackMessageType } from '@medindex-webapi';

@Injectable()
export class WriteToHelixModalService {
  constructor(private modal: ModalService) {}

  openIfns(): void {
    this.open(UserFeedbackMessageType.IssuingCertificate);
  }

  openProposal(): void {
    this.open(UserFeedbackMessageType.Proposal);
  }

  openDefault(): void {
    this.open(UserFeedbackMessageType.Question);
  }

  private open(selectedMessageType: UserFeedbackMessageType): void {
    this.modal.create({
      nzTitle: 'Написать в Хеликс',
      nzContent: WriteToHelixModalComponent,
      nzComponentParams: { selectedMessageType },
      nzStyle: { width: ModalSize.Form },
      nzClassName: 'modal-with-bg',
      nzFooter: null,
      nzMaskClosable: false,
      nzKeyboard: false,
    });
  }
}
