import { Pipe, PipeTransform } from '@angular/core';

/**
 * Преобразует число байт в строку с сокращенным размером.
 *
 * @example 1024 -> '1 KB'
 */
@Pipe({
  name: 'fileSizeToText',
  standalone: true,
})
export class FileSizeToTextPipe implements PipeTransform {
  private static readonly sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];

  transform(fileSizeInBytes: number | undefined): string {
    if (!fileSizeInBytes) return 'n/a';
    const i = Math.floor(Math.log(fileSizeInBytes) / Math.log(1024));
    return (
      parseFloat((fileSizeInBytes / Math.pow(1024, i)).toFixed(1)) +
      ' ' +
      FileSizeToTextPipe.sizes[i]
    );
  }
}
