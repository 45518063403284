import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, EMPTY, Observable, Subject } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  first,
  map,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { SearchClient, SearchResponse } from '@medindex-webapi';
import { StorageService } from '@shared/core/services/storage.service';
import { FeatureFlags } from '@shared/enums/featureFlags';

@Injectable()
export class SearcherRepositoryService implements OnDestroy {
  isOpenedResults$ = new BehaviorSubject(false);
  data$ = new BehaviorSubject<SearchResponse | null>(null);
  isSearchV2$ = this.storage.configuration$.pipe(
    filter((config) => !!config),
    map(
      (config) =>
        config?.featureFlags?.includes(FeatureFlags.HelixSearchV2) ?? false
    )
  );

  private lastQuery?: string;
  private ngUnsubscribe$ = new Subject<void>();

  constructor(
    private storage: StorageService,
    private searchClient: SearchClient
  ) {
    this.storage.cityId$
      .pipe(
        distinctUntilChanged(),
        switchMap(() =>
          this.lastQuery ? this.search(this.lastQuery, false) : EMPTY
        ),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe();
  }

  search(query: string, shouldOpenResults = true): Observable<SearchResponse> {
    this.lastQuery = query;
    return this.isSearchV2$.pipe(
      switchMap((isV2) =>
        isV2
          ? this.searchClient.searchV2({ searchQuery: query })
          : this.searchClient.search({ searchQuery: query })
      ),
      first(),
      tap((data: SearchResponse): void => {
        this.data$.next(data);
        this.isOpenedResults$.next(shouldOpenResults);
      })
    );
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}
