import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { KnowledgeBaseSearchResult } from '@medindex-webapi';
import { TabContent } from '@shared/components/searcher/types/tab-content';
import { SearcherResultCountComponent } from '../searcher-result-count/searcher-result-count.component';
import { RouterLink } from '@angular/router';
import { HelixRoutes } from '@shared/enums/routes';

@Component({
  selector: 'searcher-result-knowledge',
  templateUrl: './searcher-result-knowledge.component.html',
  standalone: true,
  imports: [CommonModule, SearcherResultCountComponent, RouterLink],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearcherResultKnowledgeComponent {
  @Input() data: TabContent = [];
  @Output() closeModal = new EventEmitter<void>();

  protected readonly HelixRoutes = HelixRoutes;

  knowledge(): KnowledgeBaseSearchResult[] {
    return this.data as KnowledgeBaseSearchResult[];
  }

  getTests(): KnowledgeBaseSearchResult[] {
    return this.knowledge().filter((item) => item.hxid);
  }

  getArticles(): KnowledgeBaseSearchResult[] {
    return this.knowledge().filter((item) => !item.hxid);
  }

  haveTests(): boolean {
    return Boolean(this.getTests().length);
  }

  haveArticles(): boolean {
    return Boolean(this.getArticles().length);
  }

  closeResults(): void {
    this.closeModal.emit();
  }
}
