import { Directive, Input, ElementRef } from '@angular/core';
import { PlatformService } from '@shared/services/platform.service';
import { Overscroll } from '@shared/directives/overscroll/overscroll';
import { GradientClass } from '@shared/directives/overscroll/enums/overscroll.enums';

/** Добавляет градиент по правой границе элемента, если есть горизонтальный скролл
 *  bgGradient отвечает за цвет градиента, по умолчанию белый */
@Directive({
  selector: '[di-overscrollX]',
  standalone: true,
})
export class OverscrollXDirective extends Overscroll {
  constructor(public element: ElementRef, public platform: PlatformService) {
    super(element, platform);
  }

  @Input() bgGradient = '';

  detectScrollPosition(eventTarget: EventTarget | null): void {
    const element: HTMLElement = eventTarget as HTMLElement;

    const hasRightOverscroll: boolean =
      Math.ceil(element.scrollLeft) + element.clientWidth !==
      element.scrollWidth;

    const classes: string = hasRightOverscroll
      ? `${GradientClass.Right} ${this.bgGradient}`
      : '';

    this.setClasses(classes);
  }
}
