import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { ForgetPassFormService } from './services/forget-pass-form.service';
import { AuthModalComponent } from './auth-modal.component';
import { AuthDatasourceService } from './services/auth-datasource.service';
import { AuthFormService } from './services/auth-form.service';
import { ModalService } from '../../services/modal/modal.service';
import { ControlErrorsModule } from '../../modules/control-errors/control-errors.module';

@NgModule({
  declarations: [AuthModalComponent],
  exports: [AuthModalComponent],
  imports: [CommonModule, ReactiveFormsModule, ControlErrorsModule],
  providers: [
    AuthDatasourceService,
    AuthFormService,
    ForgetPassFormService,
    ModalService,
  ],
})
export class AuthModalModule {}
