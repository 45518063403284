import { Injectable } from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';

@Injectable()
export class ValidationService {
  /**
   * Валидирует контролы.
   * Вернет true, если все контролы валидны.
   * @param controls
   * @param markAsDirty
   */
  validate(
    controls: Array<AbstractControl | UntypedFormControl>,
    markAsDirty?: boolean
  ): boolean {
    let isErrors = false;
    controls.forEach((control) => {
      if (control.invalid) {
        if (markAsDirty) {
          control.markAsDirty();
        }
        isErrors = true;
      }
    });

    return !isErrors;
  }
}
