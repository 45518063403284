<div (clickOutside)="clickedOutside($event)">
  <input
    [formControl]="form.control"
    class="input input-search input-cancel-button full-width"
    type="search"
    value=""
    autocomplete="off"
    maxlength="120"
    placeholder="Поиск по сайту"
    (focus)="openResult()"
  />

  <ng-container *ngIf="{
    isOpened: repository.isOpenedResults$ | async,
    data: repository.data$ | async
  } as resultsOptions">
    <searcher-results
      *ngIf="resultsOptions.isOpened && (isMobile || resultsOptions.data)"
      [data]="resultsOptions.data"
      [isMobile]="isMobile"
      [control]="form.control"
      (closeModal)="closeResult()"
    ></searcher-results>
  </ng-container>
</div>
