import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ControlErrorsComponent } from './control-errors.component';

@NgModule({
  declarations: [ControlErrorsComponent],
  exports: [ControlErrorsComponent],
  imports: [CommonModule],
})
export class ControlErrorsModule {}
