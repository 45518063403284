import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ErrorMapService {
  readonly idYandexMap = 'yandexMapsApiScript';

  hasErrorMap$ = new BehaviorSubject<boolean>(false);

  constructor(@Inject(DOCUMENT) private document: Document) {}

  handler(): void {
    const scriptYandexMap = this.document.getElementById(this.idYandexMap);

    if (scriptYandexMap) {
      scriptYandexMap.onerror = () => {
        this.hasErrorMap$.next(true);
      };
    }
  }
}
