export const calculateAge = (birthday: Date): number => {
  const today = new Date();
  let age = today.getFullYear() - birthday.getFullYear();
  const months = today.getMonth() - birthday.getMonth();

  if (months < 0 || (months === 0 && today.getDate() < birthday.getDate())) {
    age--;
  }
  return age;
};
