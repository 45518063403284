<div class="overflow-hidden">
  <searcher-result-count [count]="address().length"></searcher-result-count>

  <div class="list">
    <a
      *ngFor="let address of address()"
      [routerLink]="[HelixRoutes.Center, address.code]"
      (click)="closeResults()"
      class="list-item grid grid-gutter-8"
    >
      <div class="grid-item grid-12">
        <div class="typography typography-body grey-800">
          {{ address.accountName }}
        </div>
      </div>

      <div class="grid-item grid-12">
        <div class="typography typography-body grey-500">
          {{ address.cityName | upperFirstLetter }}, {{ address.address }}
        </div>
        <div
          *ngIf="address.metroName as metro"
          class="typography typography-body grey-500"
        >
          Станция метро: {{ metro }}
        </div>
      </div>
    </a>
  </div>
</div>
