import {
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { HttpResponseStatuses } from '../enums/http-statuses';
import { ModalService } from '../services/modal/modal.service';
import { StorageService } from '../core/services/storage.service';
import { ServiceUnavailableModalComponent } from '../modules/service-unavailable-modal/service-unavailable-modal.component';
import { ModalSize } from '@shared/enums/modal-sizes';

const ErrorStatuses = [
  HttpResponseStatuses.InternalServerError,
  HttpResponseStatuses.GatewayTimeout,
  HttpResponseStatuses.ServiceUnavailable,
];

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private modal: ModalService,
    private storage: StorageService,
    // eslint-disable-next-line @typescript-eslint/ban-types
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    return next.handle(req).pipe(
      catchError((error) => {
        if (isPlatformBrowser(this.platformId)) {
          if (ErrorStatuses.includes(error.status)) {
            if (!this.storage.hasActivatedErrorModal) {
              this.modal.create({
                nzContent: ServiceUnavailableModalComponent,
                nzFooter: null,
                nzStyle: {
                  width: ModalSize.Auto,
                  maxWidth: ModalSize.Warning,
                },
                nzOnCancel: () => {
                  this.storage.hasActivatedErrorModal = false;
                },
              });

              this.storage.hasActivatedErrorModal = true;
            }
          }
        }
        return throwError(() => error);
      })
    );
  }
}
