<div class="cities-template mt-32" #citiesListContainer>
  <ng-container
    *ngFor="let tag of repository.citiesByTag$ | async; let i = index"
  >
    <div
      class="typography typography-h4"
      #cityTag
      [id]="partialTagId + tag.name"
    >
      {{ tag.name }}
    </div>
    <div class="divider"></div>
    <div class="grid mb-24">
      <div
        *ngFor="let city of tag.cities; let i = index"
        class="grid-item grid-3 grid-md-4 grid-sm-6 grid-xs-12"
      >
        <span
          class="typography link"
          [class]="
            city.id === (storage.cityId$ | async) ? 'green-basic' : 'grey-500'
          "
          (click)="repository.selectCity(city.id)"
          [attr.data-testid]="'city-by-tag-' + i"
        >
          {{ city.name | upperFirstLetter }}
        </span>
      </div>
    </div>
  </ng-container>
</div>
