import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VisuallyImpairedComponent } from './visually-impaired.component';
import { VisuallyImpairedLayoutComponent } from './components/visually-impaired-layout/visually-impaired-layout.component';
import { CookieService } from '@shared/services/cookie.service';
import { VisuallyImpairedService } from '@shared/services/visually-impaired.service';

@NgModule({
  declarations: [VisuallyImpairedComponent, VisuallyImpairedLayoutComponent],
  exports: [VisuallyImpairedComponent, VisuallyImpairedLayoutComponent],
  imports: [CommonModule],
  providers: [CookieService, VisuallyImpairedService],
})
export class VisuallyImpairedModule {}
