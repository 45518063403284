<app-visually-impaired></app-visually-impaired>
<app-helix-header></app-helix-header>
<div class="typography">
  <router-outlet></router-outlet>
</div>
<hlx-footer
  [phoneNumbers]="{
    company: (storage.companyPhone$ | async),
    federal: (storage.federalPhone$ | async)
  }"
></hlx-footer>

<app-mobile-bottom-navigation></app-mobile-bottom-navigation>
