import {
  ValidationErrors,
  Validators,
  ValidatorFn,
  AbstractControl,
} from '@angular/forms';
import { LEGAL_AGE_DATE } from '../../utils/legalAgeDate';
// eslint-disable-next-line import/no-cycle
import { CustomValidators } from '../custom-validators';

export const User: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  if (!control) return null;

  const birthDate: Date = control?.get('birthDate')?.value;
  const cellPhone = control?.get('cellPhone');
  const email = control?.get('email');

  if (birthDate < LEGAL_AGE_DATE) {
    cellPhone?.setValidators([Validators.required, CustomValidators.Phone]);
    email?.setValidators([Validators.required, CustomValidators.Email]);
  } else {
    cellPhone?.clearValidators();
    email?.clearValidators();
  }

  cellPhone?.updateValueAndValidity({ emitEvent: false, onlySelf: true });
  email?.updateValueAndValidity({ emitEvent: false, onlySelf: true });

  return null;
};
