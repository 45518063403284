<div
  [class.cities-search-result]="(repository.searchedCities$ | async)?.length"
  class="grid"
  style="align-content: flex-start"
  data-testid="important-cities"
>
  <div
    *ngIf="
      !(repository.searchedCities$ | async)?.length &&
      (repository.searchPhrase$ | async)?.length
    "
    class="empty empty-s"
  >
    <span class="typography" data-testid="not-exist-city-msg"
      >В указанном городе пока нет Центра Хеликс</span
    >
  </div>
  <div
    *ngFor="let city of repository.searchedCities$ | async; let i = index"
    class="grid-item grid-3 grid-md-4 grid-sm-6 grid-xs-12 pb-4"
  >
    <span
      [class]="
        city.id === (storage.cityId$ | async) ? 'green-basic' : 'gray-900'
      "
      class="typography typography-medium link"
      (click)="repository.selectCity(city.id)"
      [attr.data-testid]="'important-city-' + i"
    >
      {{ city.name | upperFirstLetter }}
    </span>
  </div>
</div>
