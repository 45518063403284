import { Birthdate } from './common/birthdate.validator';
import { Email } from './common/email.validator';
import { Name } from './common/name.validator';
import { Phone } from './common/phone.validator';
import { User } from './user/user.validator';
import { OrderCode } from './common/order-code.validator';

export class CustomValidators {
  public static Name = Name;
  public static Email = Email;
  public static Phone = Phone;
  public static Birthdate = Birthdate;
  public static User = User;
  public static OrderCode = OrderCode;
}
