import { inject, Injectable, OnDestroy } from '@angular/core';
import {
  BehaviorSubject,
  EMPTY,
  of,
  Subject,
  switchMap,
  tap,
  throwError,
  withLatestFrom,
} from 'rxjs';
import { catchError, filter, takeUntil } from 'rxjs/operators';
import { HttpResponseStatuses } from '@shared/enums/http-statuses';
import { ProfileClient, UserAccountClient } from '@medindex-webapi';
import {
  GetCurrentUserAccountResponse,
  ProfileResponse,
} from '@medindex-webapi/lib/web-api-client';
import { StorageService } from '@shared/core/services/storage.service';

enum ProfileStatus {
  LoggedIn = 'LoggedIn',
  ProfileNotSet = 'ProfileNotSet',
  NotLoggedIn = 'NotLoggedIn',
}

@Injectable()
export class UserInfoService implements OnDestroy {
  private userAccountClient = inject(UserAccountClient);
  private profileClient = inject(ProfileClient);
  private storage = inject(StorageService);

  private statusProfile$ = new BehaviorSubject<ProfileStatus>(
    ProfileStatus.NotLoggedIn
  );
  private hasProfile$ = new BehaviorSubject<boolean>(true);
  private userInfo$ = new BehaviorSubject<GetCurrentUserAccountResponse | null>(
    null
  );

  userNickName$ = new BehaviorSubject<string | null>(null);
  profileInfo$ = new BehaviorSubject<ProfileResponse | null>(null);

  private ngUnsubscribe$ = new Subject<void>();

  loadUserInfo(): void {
    this.resetUserInfoCache();
    this.getCurrentProfile();
    this.setCurrentProfile();
    this.getCurrentAccount();
    this.setUserNickName();
  }

  private getCurrentProfile(): void {
    this.profileClient
      .getCurrentProfile()
      .pipe(
        tap((profileInfo): void => {
          this.setProfileInfo(profileInfo);
        }),
        catchError((error) => {
          if (error.status === HttpResponseStatuses.NoContent) {
            this.profileInfo$.next(null);
            this.statusProfile$.next(ProfileStatus.ProfileNotSet);

            return of(null);
          }

          this.profileInfo$.next(null);
          this.statusProfile$.next(ProfileStatus.NotLoggedIn);

          console.debug('Failed to get current user profile');
          return EMPTY;
        }),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe();
  }

  private setCurrentProfile(): void {
    this.statusProfile$
      .pipe(
        filter((status) => status === ProfileStatus.ProfileNotSet),
        // проставляем первый профиль из привязанных к аккаунту
        switchMap(() => this.profileClient.setCurrentProfile({ id: null })),
        catchError(() => of(null)),
        tap((currentProfile): void => {
          if (currentProfile) {
            this.setProfileInfo(currentProfile);
          } else {
            this.profileInfo$.next(null);
            this.hasProfile$.next(false);
          }
        }),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe();
  }

  private getCurrentAccount(): void {
    this.hasProfile$
      .pipe(
        filter((hasProfile) => !hasProfile),
        switchMap(() => this.userAccountClient.getCurrentAccount()),
        tap((userInfo): void => {
          this.userInfo$.next(userInfo);
          this.statusProfile$.next(ProfileStatus.LoggedIn);
        }),
        catchError((error) => {
          this.userInfo$.next(null);
          this.statusProfile$.next(ProfileStatus.NotLoggedIn);

          return throwError(() => error);
        }),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe();
  }

  private setUserNickName(): void {
    this.statusProfile$
      .pipe(
        filter((status) => status === ProfileStatus.LoggedIn),
        withLatestFrom(this.profileInfo$, this.userInfo$),
        tap(([, profileInfo, userInfo]): void => {
          if (profileInfo) {
            this.userNickName$.next(
              `${profileInfo.firstName} ${profileInfo.lastName[0]}.`
            );
            return;
          }

          if (userInfo) {
            this.userNickName$.next(userInfo.email);
            return;
          }
        }),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe();
  }

  private setProfileInfo(profileInfo: ProfileResponse): void {
    this.storage.setLayoutViewDataCache({
      ...this.storage.getLayoutViewDataCache(),
      userInfo: { hasProfile: true, profile: profileInfo },
    });

    this.profileInfo$.next(profileInfo);
    this.statusProfile$.next(ProfileStatus.LoggedIn);
    this.hasProfile$.next(true);
  }

  private resetUserInfoCache(): void {
    this.storage.setLayoutViewDataCache({
      ...this.storage.getLayoutViewDataCache(),
      userInfo: null,
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}
