import { Observable, Subject, throwError } from 'rxjs';
/* eslint-disable no-param-reassign */
import { ErrorMessages } from 'src/app/shared/types/error-messages.enum';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';

import { catchError, filter, map } from 'rxjs/operators';
import { HttpResponseStatuses } from 'src/app/shared/enums/http-statuses';
import {
  ForgetPassError,
  ForgetPassRequest,
  EnterStatus,
  LoginRequest,
  LoginResult,
  LoginResultStatus,
} from '../../../types/types';

import { DatasourceService } from '../../../core/services/datasource.service';

@Injectable()
export class AuthDatasourceService {
  constructor(
    private dataSource: DatasourceService,
    private http: HttpClient
  ) {}

  signInResult$ = new Subject<LoginResult | null>();
  authError$ = new Subject<string | null>();
  forgetPassError$ = new Subject<string | null>();

  signIn(data: LoginRequest): Observable<LoginResult | null> {
    return this.http
      .post<LoginResult | null>('/api/userAccounts/login', data, {
        observe: 'response',
      })
      .pipe(
        catchError((error) => {
          if (error.status === HttpResponseStatuses.BusinessLogicError) {
            const enterStatus = error.error.Message as EnterStatus;
            switch (enterStatus) {
              case EnterStatus.AccountNotActivated:
                this.authError$.next(ErrorMessages.AccountNotActivated);
                break;
              case EnterStatus.SuchUserNotRegisteredAsClient:
                this.authError$.next(
                  ErrorMessages.SuchUserNotRegisteredAsClient
                );
                break;
              case EnterStatus.UserWithSuchEmailAndPasswordNotFound:
                this.authError$.next(
                  ErrorMessages.UserWithSuchEmailAndPasswordNotFound
                );
                break;
              default:
                break;
            }

            return throwError(error);
          }

          return this.dataSource.errorHandler(error);
        }),
        map((response: HttpResponse<LoginResult | null>) =>
          this.dataSource.checkResponseStatus<LoginResult | null>(response)
        ),
        filter(
          (result: LoginResult | null) =>
            result?.status === LoginResultStatus.Success
        )
      );
  }

  forgetPass(data: ForgetPassRequest): Observable<null> {
    return this.http
      .post<null>('/api/userAccounts/forgetPass', data, {
        observe: 'response',
      })
      .pipe(
        catchError((error) => {
          if (error.status === HttpResponseStatuses.BusinessLogicError) {
            const enterStatus = error.error.Message as ForgetPassError;
            switch (enterStatus) {
              case ForgetPassError.SuchUserNotRegistered:
                this.forgetPassError$.next(ErrorMessages.SuchUserNotRegistered);
                break;
              case ForgetPassError.SuchUserNotRegisteredAsClient:
                this.forgetPassError$.next(
                  ErrorMessages.SuchUserNotRegisteredAsClient
                );
                break;
              default:
                break;
            }

            return throwError(error);
          }

          return this.dataSource.errorHandler(error);
        }),
        map((response: HttpResponse<null>) =>
          this.dataSource.checkResponseStatus<null>(response)
        )
      );
  }
}
