export enum HelixRoutePage {
  HowGetResult = '/site/page/86',
  PreorderInfo = '/site/page/53',
  LkkInfo = '/site/page/65',
  PrepareInfo = '/site/page/59',
  DmsInfo = '/site/page/52',
  Contacts = '/site/page/107',
  QualityControl = '/site/page/116',
  Licenses = '/site/page/46',
}
