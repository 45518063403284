import { Injectable } from '@angular/core';
import { SearchResponse } from '@medindex-webapi';
import { ITab } from '@shared/components/searcher/types/tab';
import { SearcherResultsDatasourceService } from './searcher-results-datasource.service';

@Injectable()
export class SearcherResultsRepositoryService {
  constructor(private datasource: SearcherResultsDatasourceService) {}
  tabs: ITab[] = [];
  tab: ITab | null = null;

  getTabs(data: SearchResponse | null): void {
    if (data) {
      this.tabs = this.datasource.tabs(data);
    } else {
      this.tabs = [];
    }
    this.activeTab();
  }

  activateTab(newActivatedIndex: number) {
    const currentActivatedIndex = this.tabs.findIndex((tab) => tab.activated);
    this.tabs[currentActivatedIndex].activated = false;
    this.tabs[newActivatedIndex].activated = true;
    this.activeTab();
  }

  activeTab(): void {
    this.tab = this.tabs.find((tab) => tab.activated) || null;
  }
}
