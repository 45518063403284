<form [formGroup]="form" *ngIf="form">
  <div class="grid grid-column grid-gutter-28">
    <div class="grid-item hidden-xs">
      <div class="grid">
        <div
          *ngFor="let messageType of messageTypes"
          [id]="messageType.id"
          (click)="selectMessageType(messageType.id)"
          class="message-type-button"
          [ngClass]="{ button: selectedMessageType === messageType.id }"
        >
          {{ messageType.label }}
        </div>
      </div>

      <div class="divider my-12"></div>
    </div>

    <div class="grid-item">
      <div class="grid grid-gutter-20">
        <div class="grid-item grid-6 grid-xs-12 block-xs hidden">
          <div class="form-item form-item-required">
            <div class="form-item-label typography typography-medium">
              Выберите тему обращения
            </div>
            <div class="form-item-control">
              <select
                data-testid="message-type-selector"
                class="select full-width"
                (change)="selectMessageType($any($event.target)?.value)"
              >
                <option
                  *ngFor="let messageType of messageTypes"
                  [value]="messageType.id"
                  [selected]="selectedMessageType === messageType.id"
                >
                  {{ messageType.label }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="grid-item grid-6 grid-xs-12">
          <div class="form-item">
            <div class="form-item-label typography typography-medium">Имя</div>
            <div class="form-item-control">
              <input
                data-testid="message-name-input"
                class="input"
                type="text"
                name="Name"
                maxlength="100"
                formControlName="name"
              />
            </div>
          </div>
        </div>
        <div class="grid-item grid-6 grid-xs-12">
          <div class="form-item form-item-required">
            <div class="form-item-label typography typography-medium">
              E-mail
            </div>
            <div class="form-item-control">
              <input
                data-testid="message-email-input"
                class="input"
                type="text"
                name="Email"
                placeholder="name@email.ru"
                formControlName="email"
              />
            </div>
            <ng-container
              *ngIf="form.controls.email.touched && form.controls.email.errors"
            >
              <div class="typography typography-body red">
                <div
                  data-testid="message-email-required-error-message"
                  *ngIf="form.controls.email.errors.required"
                >
                  Укажите e-mail
                </div>
                <div
                  data-testid="message-email-format-error-message"
                  *ngIf="form.controls.email.errors.emailFormat"
                >
                  E-mail имеет неверный формат
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>

    <div class="grid-item">
      <div class="form-item form-item-required">
        <div class="form-item-label typography typography-medium">
          Текст сообщения
        </div>
        <div class="form-item-control">
          <textarea
            data-testid="message-text-input"
            rows="4"
            class="input full-width textarea textarea_vertical-resize"
            name="Message"
            formControlName="message"
          ></textarea>
        </div>
        <ng-container
          *ngIf="form.controls.message.touched && form.controls.message.errors"
        >
          <div class="typography typography-body red">
            <div
              data-testid="message-text-required-error-message"
              *ngIf="form.controls.message.errors.required"
            >
              Текст сообщения не заполнен
            </div>
          </div>
        </ng-container>
      </div>
    </div>

    <div class="grid-item grid-xs-12">
      <div class="form-item">
        <div class="form-item-label typography typography-medium">
          Номер заказа
        </div>
        <div class="form-item-control">
          <div class="grid grid-gutter-20 order-number-area">
            <div class="grid-item grid-6 grid-xs-12">
              <input
                data-testid="message-order-code-input"
                class="input"
                type="text"
                name="OrderCode"
                formControlName="orderCode"
              />
            </div>
            <div class="grid-item grid-6 grid-xs-12">
              <div class="typography typography-body grey-500">
                Если вопрос связан с вашим заказом, укажите, пожалуйста, его
                номер
              </div>
            </div>
          </div>
          <ng-container
            *ngIf="
              form.controls.orderCode.touched && form.controls.orderCode.errors
            "
          >
            <div class="typography typography-body red">
              <div
                data-testid="message-order-code-format-error-message"
                *ngIf="form.controls.orderCode.errors.orderCodeFormat"
              >
                Номер заказа имеет неверный формат. Пример: 5218-D681-192143708
                или 12345-12345-12345678
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>

    <div class="grid-item">
      <div class="divider"></div>
    </div>

    <div class="grid-item">
      <app-write-to-helix-file-upload
        (filesChanged)="updateFiles($event)"
        [totalMaxSize]="10240"
      >
      </app-write-to-helix-file-upload>
    </div>

    <div class="grid-item">
      <div class="divider"></div>
    </div>

    <div class="grid-item">
      <label class="grid grid-gutter-12 grid-nowrap">
        <div class="grid-item">
          <span class="checkbox">
            <input
              data-testid="accept-input"
              type="checkbox"
              class="checkbox-input"
              formControlName="accept"
            />
            <div class="checkbox-mark"></div>
          </span>
        </div>
        <div class="grid-item grid-8 grid-xs-11">
          <span class="typography typography-body">
            Я даю свое согласие на
            <a
              target="_blank"
              class="typography typography-body green-basic typography-underline"
              [href]="Agreements.PersonalDataProcessingAgreement"
            >
              обработку персональных</a
            >
            данных и ознакомлен с
            <a
              target="_blank"
              class="typography typography-body green-basic typography-underline"
              [href]="Agreements.PrivateAgreement"
            >
              Условиями пользовательского соглашения
            </a>
          </span>
        </div>
      </label>
    </div>

    <div class="grid-item" *ngIf="recaptchaKey$ | async as recaptchaKey">
      <re-captcha
        formControlName="recaptcha"
        size="compact"
        badge="inline"
        errorMode="handled"
        [siteKey]="recaptchaKey"
        (error)="onRecaptchaError()"
      >
      </re-captcha>
    </div>

    <div class="grid-item">
      <hlx-button
        class="full-width-xs"
        [isLoading]="!!(isLoadingSubmit$ | async)"
        [disabled]="form.invalid || !!(isLoadingSubmit$ | async)"
        (click)="onSubmit()"
        data-testid="send-message-button"
      >
        Отправить сообщение
      </hlx-button>
    </div>

    <div class="h-80"></div>
  </div>
</form>
