import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ItemNavigation } from '@shared/components/mobile-bottom-navigation/interfaces/bottom-navigation.interfaces';
import { NgForOf } from '@angular/common';
import { MobileBottomNavigationDatasourceService } from '@shared/components/mobile-bottom-navigation/services/mobile-bottom-navigation.datasource.service';
import { RouterLink } from '@angular/router';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { AnimationState } from '@shared/components/mobile-bottom-navigation/enums/animation-state.enums';
import { BurgerComponent } from '@shared/components/burger/burger.component';
import { PageScrollingService } from '@shared/services/page-scrolling';

@Component({
  selector: 'app-mobile-bottom-navigation',
  templateUrl: './mobile-bottom-navigation.component.html',
  styleUrls: ['./mobile-bottom-navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [RouterLink, NgForOf, BurgerComponent],
  providers: [MobileBottomNavigationDatasourceService, PageScrollingService],
  animations: [
    trigger('flyInOut', [
      state(AnimationState.In, style({ transform: 'translateX(100%)' })),
      state(AnimationState.Out, style({ transform: 'translateX(0)' })),
      transition(`${AnimationState.In} <=> ${AnimationState.Out}`, [
        animate('0.2s ease-in-out'),
      ]),
    ]),
  ],
})
export class MobileBottomNavigationComponent {
  constructor(
    private datasource: MobileBottomNavigationDatasourceService,
    private pageScrolling: PageScrollingService
  ) {}

  animationState: AnimationState = AnimationState.In;

  readonly itemsNavigation: ItemNavigation[] =
    this.datasource.itemsNavigation();

  openBurger(): void {
    this.pageScrolling.togglePageScroll(true);
    this.animationState = AnimationState.Out;
  }

  closeBurger(): void {
    this.pageScrolling.togglePageScroll(false);
    this.animationState = AnimationState.In;
  }
}
