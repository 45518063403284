import { Injectable } from '@angular/core';
import { Observable, from, switchMap, catchError, map, of } from 'rxjs';
import * as FingerprintJS from '@fingerprintjs/fingerprintjs';

@Injectable()
export class FingerprintService {
  getUserId(): Observable<string> {
    return from(FingerprintJS.load()).pipe(
      switchMap((agent) => agent.get()),
      map((getResult) => getResult.visitorId),
      catchError((error) => {
        console.error(error);
        return of('');
      })
    );
  }
}
