import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HeaderRepositoryService } from './services/header-repository.service';
import { HeaderComponent } from './components/header/header.component';
import { SharedModule } from '../../shared.module';
import { ModalAppointmentDoctorModule } from '../modal-appointment-doctor/modal-appointment-doctor.module';
import { CityComponent } from '@shared/components/city/city.component';
import { VisuallyImpairedButtonComponent } from '@shared/components/visually-impaired-button/visually-impaired-button.component';
import { VisuallyImpairedService } from '@shared/services/visually-impaired.service';
import { OverscrollXDirective } from '@shared/directives/overscroll/x-overscroll';
import { SearcherModule } from '@shared/components/searcher/searcher.module';
import { YaReachGoalDirective } from '@shared/directives/ym-reach-goal.directive';
import { ProfileClient, UserAccountClient } from '@medindex-webapi';
import { UserInfoService } from '@shared/modules/header/services/user-info.service';
import { UserInfoButtonComponent } from '@shared/modules/header/components/user-info-button/user-info-button.component';

@NgModule({
  declarations: [HeaderComponent],
  exports: [HeaderComponent],
  providers: [
    HeaderRepositoryService,
    VisuallyImpairedService,
    UserAccountClient,
    ProfileClient,
    UserInfoService,
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
    ModalAppointmentDoctorModule,
    OverscrollXDirective,
    CityComponent,
    SearcherModule,
    VisuallyImpairedButtonComponent,
    YaReachGoalDirective,
    UserInfoButtonComponent,
  ],
})
export class HeaderModule {}
