import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
  OnInit,
} from '@angular/core';
import { AsyncPipe, NgIf } from '@angular/common';
import { ProfileResponse } from '@medindex-webapi/lib/web-api-client';
import { calculateAge } from '@shared/utils/calculate-age';
import { DeclensionService } from '@shared/services/declension.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-user-info-popover',
  templateUrl: './user-info-popover.component.html',
  styleUrls: ['./user-info-popover.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, AsyncPipe],
  providers: [DeclensionService],
})
export class UserInfoPopoverComponent implements OnInit {
  private declensionService = inject(DeclensionService);

  private _userInfo: ProfileResponse | null = null;
  get userInfo() {
    return this._userInfo;
  }

  @Input() set userInfo(value: ProfileResponse | null) {
    if (value) {
      this.age = value.birthDate ? this.getAge(value.birthDate) : null;
      this.points = this.declensionPoints(value.cardBalance);
    }

    this._userInfo = value;
  }

  @Input() userNickName: string | null = null;
  @Input() loginLink: string | null = null;
  @Input() personalAccount: string | null = null;

  age: string | null = null;
  points = '';

  exitLink = '';

  ngOnInit(): void {
    this.generateExitLink();
  }

  private generateExitLink(): void {
    const isOnCartRoute = window.location.pathname
      .split('/')
      .some((urlSegment) => urlSegment === 'preorder');

    const urlToReturn = isOnCartRoute
      ? window.location.origin
      : window.location.href;

    this.exitLink = `${this.loginLink}exit?returnUrl=${urlToReturn}`;
  }

  private getAge(birthday: Date): string {
    const age = calculateAge(new Date(birthday));
    return this.declensionAge(age);
  }

  private declensionAge(age: number): string {
    return this.declensionService.getDeclension(age, [
      `${age} год`,
      `${age} года`,
      `${age} лет`,
    ]);
  }

  private declensionPoints(points: number): string {
    return this.declensionService.getDeclension(points, [
      `${points} балл`,
      `${points} балла`,
      `${points} баллов`,
    ]);
  }
}
