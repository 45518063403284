import { Injectable } from '@angular/core';
import { ModalService } from '@shared/services/modal/modal.service';
import { ReportErrorModalComponent } from '../report-error-modal.component';
import { ModalSize } from '@shared/enums/modal-sizes';

@Injectable()
export class ReportErrorModalService {
  constructor(private modal: ModalService) {}

  open(): void {
    this.modal.create({
      nzTitle: 'Сообщить об ошибке на сайте',
      nzContent: ReportErrorModalComponent,
      nzStyle: { width: ModalSize.Form },
      nzClassName: 'modal-with-bg',
      nzFooter: null,
      nzMaskClosable: false,
      nzKeyboard: false,
    });
  }
}
