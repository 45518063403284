<div class="grid grid-gutter-4" data-testid="all-city-tags">
  <div
    class="grid-item"
    *ngFor="let tag of repository.citiesByTag$ | async; let i = index"
    [attr.data-testid]="'city-tag-' + i"
  >
    <div
      [class]="
        (repository.currentTag$ | async) === tag.name
          ? 'bg-green-basic white'
          : 'bg-white grey-800 tag-border'
      "
      (click)="scrollToTag(tag.name)"
      class="city-tag typography typography-headline grid align-items-center justify-content-center rounded-xs cursor-pointer"
      data-testid="city-tag"
    >
      {{ tag.name }}
    </div>
  </div>
</div>
