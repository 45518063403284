import { Injectable } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { FormService } from '../../../core/services/form.service';

@Injectable()
export class ForgetPassFormService extends FormService<UntypedFormGroup> {
  constructor(private builder: UntypedFormBuilder) {
    super();
    this.create();
  }

  protected create(): void {
    this.form = this.builder.group({
      email: [
        null,
        [
          Validators.required,
          Validators.pattern(
            new RegExp(/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/)
          ),
        ],
      ],
    });
  }
}
