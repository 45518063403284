import { AbstractControl, ValidationErrors } from '@angular/forms';
import { DEFAULT_MIN_DATE, DEFAULT_MAX_DATE } from '../../utils/defaultDates';

export function Birthdate(control: AbstractControl): ValidationErrors | null {
  const { value } = control;
  const minBirthdate = value < DEFAULT_MIN_DATE;
  const maxBirthdate = value > DEFAULT_MAX_DATE;

  return minBirthdate || maxBirthdate ? { minBirthdate, maxBirthdate } : null;
}
