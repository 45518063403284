import { Injectable } from '@angular/core';
import {
  AbstractControl,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Gender } from '../../types/types';
import { ValidationPatterns } from './validation-patterns.const';
import { clearPhone } from '../../utils/utils';

@Injectable()
export class ValidationRulesService {
  gender(): ValidatorFn {
    const pattern = `^(${Gender.Male}|${Gender.Female})$`;
    return Validators.pattern(new RegExp(pattern));
  }

  name(): ValidatorFn {
    return Validators.pattern(new RegExp(ValidationPatterns.Name));
  }

  orderCode(): ValidatorFn {
    const orderCodeRegExp1 = new RegExp(ValidationPatterns.OrderCode1);
    const orderCodeRegExp2 = new RegExp(ValidationPatterns.OrderCode2);

    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) return null;

      const valid =
        orderCodeRegExp1.test(control.value) ||
        orderCodeRegExp2.test(control.value);

      return valid ? null : { pattern: true };
    };
  }

  email(): ValidatorFn {
    return Validators.pattern(new RegExp(ValidationPatterns.Email));
  }

  phone(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) return null;

      const clearedPhone = clearPhone(control.value);
      const regexp = new RegExp(ValidationPatterns.Phone);
      const valid = regexp.test(clearedPhone);

      return valid ? null : { pattern: true };
    };
  }
}
