import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CityRepositoryService } from './services/city-repository.service';
import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import { InputModule } from '@medindex';
import { CitiesClient } from '@medindex-webapi';
import { CountrySelectComponent } from './components/country-select/country-select.component';
import { CitySearchComponent } from './components/city-search/city-search.component';
import { CityListComponent } from './components/city-list/city-list.component';
import { CityFormService } from './services/city-form.service';
import { CityTagsComponent } from './components/city-tags/city-tags.component';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';

@Component({
  selector: 'app-city',
  templateUrl: './city.component.html',
  styleUrls: ['./city.component.scss'],
  providers: [CitiesClient, CityFormService, CityRepositoryService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    InputModule,
    AsyncPipe,
    NgIf,
    NgForOf,
    CountrySelectComponent,
    CitySearchComponent,
    CityListComponent,
    CityTagsComponent,
    NzSkeletonModule,
  ],
})
export class CityComponent {
  constructor(
    public formService: CityFormService,
    public repository: CityRepositoryService
  ) {}
}
