import { Injectable } from '@angular/core';

@Injectable()
export class DeclensionService {
  getDeclension(num: number, declensions: string[]): string {
    const oneValue = Math.abs(num) % 100;
    const twoValue = oneValue % 10;
    if (oneValue > 10 && oneValue < 20) return declensions[2];
    if (twoValue > 1 && twoValue < 5) return declensions[1];
    if (twoValue === 1) return declensions[0];
    return declensions[2];
  }
}
