import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse } from '../types/api-response.type';
import { IConfiguration } from '../types/configuration.type';
import {
  processSuccessApiResponse,
  processErrorApiResponse,
} from '../utils/utils';
import { StorageService } from '../core/services/storage.service';
import { YaConfig } from 'angular8-yandex-maps';

export const yaMapConfig: YaConfig = {
  apikey: '',
  lang: 'ru_RU',
};

@Injectable()
export class ConfigurationService {
  constructor(private http: HttpClient, private storage: StorageService) {}

  // WARNING call only on app bootstrap
  async init(): Promise<void> {
    const configurationResponse = await this.fetchConfiguration();

    if (
      configurationResponse &&
      configurationResponse.success &&
      configurationResponse.payload
    ) {
      this.storage.setConfiguration(configurationResponse.payload);

      yaMapConfig.apikey = configurationResponse.payload.yandexMaps.apiKey;
      yaMapConfig.lang = configurationResponse.payload.yandexMaps.locale;
    }
  }

  private async fetchConfiguration(): Promise<
    ApiResponse<IConfiguration | undefined>
  > {
    return this.http
      .get<IConfiguration | undefined>(`/api/configuration`)
      .toPromise()
      .then((response) =>
        processSuccessApiResponse<IConfiguration | undefined>(response)
      )
      .catch((error) => processErrorApiResponse(error));
  }
}
