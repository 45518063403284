import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ModalErrorType } from '../../enums/modal-error-type';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'warning-modal',
  templateUrl: './warning-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule],
})
export class WarningModalComponent {
  @Input() content: string | undefined;
  @Input() title: string | undefined;
  @Input() errorType: ModalErrorType = ModalErrorType.Error;

  get ModalErrorType(): typeof ModalErrorType {
    return ModalErrorType;
  }
}
