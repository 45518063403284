import { AbstractControl, ValidationErrors } from '@angular/forms';
import { ValidationPatterns } from '../../services/validation/validation-patterns.const';

export function OrderCode(control: AbstractControl): ValidationErrors | null {
  const { value } = control;
  if (!value) return null;

  const orderCodeRegExp1 = new RegExp(ValidationPatterns.OrderCode1);
  const orderCodeRegExp2 = new RegExp(ValidationPatterns.OrderCode2);

  return orderCodeRegExp1.test(value) || orderCodeRegExp2.test(value)
    ? null
    : { orderCodeFormat: 'Номер заказа указан в неверном формате' };
}
