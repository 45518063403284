import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { PromotionSearchResult } from '@medindex-webapi';
import { TabContent } from '@shared/components/searcher/types/tab-content';
import { ClearHtmlPipeModule } from '@shared/pipes/clear-html/clear-html-pipe.module';
import { SearcherResultCountComponent } from '../searcher-result-count/searcher-result-count.component';
import { RouterLink } from '@angular/router';
import { HelixRoutes } from '@shared/enums/routes';

@Component({
  selector: 'searcher-result-promotion',
  templateUrl: './searcher-result-promotion.component.html',
  standalone: true,
  imports: [
    CommonModule,
    ClearHtmlPipeModule,
    SearcherResultCountComponent,
    RouterLink,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearcherResultPromotionComponent {
  @Input() data: TabContent = [];
  @Output() closeModal = new EventEmitter<void>();

  protected readonly HelixRoutes = HelixRoutes;

  promotion(): PromotionSearchResult[] {
    return this.data as PromotionSearchResult[];
  }

  closeResults(): void {
    this.closeModal.emit();
  }
}
