import { Injectable } from '@angular/core';
import { CityResponse } from '@medindex-webapi';
import { PlatformService } from '@shared/services/platform.service';
import { IConfiguration } from '@shared/types/configuration.type';
import { BehaviorSubject, Observable, filter, map } from 'rxjs';
import { Configuration } from '../../entities/configuration';
import { Country } from '@shared/enums/country.enum';
import { upperFirstLetter } from '@shared/utils/upper-first-letter.util';
import { ProfileResponse } from '@medindex-webapi/lib/web-api-client';

const LAYOUT_VIEW_DATA_STORAGE_KEY = 'Helix.LayoutViewData';

type ProfileInfo = {
  hasProfile: true;
  profile: ProfileResponse;
};

export type LayoutViewData = {
  currentCity: CityResponse;
  cartItemsCount: number;
  config: IConfiguration;
  userInfo: ProfileInfo | null;
  showCitySuggest: boolean;
};

@Injectable()
export class StorageService {
  constructor(private platformService: PlatformService) {}

  private readonly _city$ = new BehaviorSubject<CityResponse | null>(null);
  private readonly _configuration$ = new BehaviorSubject<IConfiguration>(
    new Configuration()
  );

  hasActivatedErrorModal = false;
  city$: Observable<CityResponse> = this._city$
    .asObservable()
    .pipe(filter((city): city is CityResponse => !!city));
  cityId$ = this.city$.pipe(map((city) => city.id));
  cityName$ = this.city$.pipe(map((city) => upperFirstLetter(city.name)));
  country$ = this.city$.pipe(map((city) => city.countryCode as Country));
  companyPhone$ = this.city$.pipe(map((city) => city.companyPhone));
  federalPhone$ = this.city$.pipe(map((city) => city.federalPhone));
  configuration$ = this._configuration$
    .asObservable()
    .pipe(
      filter(
        (configuration): configuration is IConfiguration => !!configuration
      )
    );

  defaultViewData: LayoutViewData = {
    currentCity: {
      id: -1,
      name: '',
      federalPhone: '',
      companyPhone: '',
      isImportant: false,
      isParent: false,
      alias: '',
      countryCode: '',
    },
    cartItemsCount: 0,
    config: {
      recaptchaPublicKey: '',
      phoneNumber: '',
      hosts: {
        main: '',
        personalAccount: '',
        doctorAccount: '',
        login: '',
        b2b: '',
      },
      webim: {
        accountName: '',
        domain: '',
      },
      clientStorage: {
        isUserLoggedIn: false,
      },
      yandexMaps: {
        apiKey: '',
        locale: 'ru_RU',
      },
      featureFlags: null,
    },
    userInfo: null,
    showCitySuggest: true,
  };

  /**
   * Применяет город к сайту.
   * @param city Город для применения.
   * @param saveCityInLocalStorage Сохранить ли текущим городом в localStorage (по дефолту true).
   */
  setCity(city: CityResponse, saveCityInLocalStorage = true): void {
    this._city$.next(city);
    if (!saveCityInLocalStorage) return;
    const cachedLayoutData = this.getLayoutViewDataCache();
    this.setLayoutViewDataCache({ ...cachedLayoutData, currentCity: city });
  }

  setConfiguration(config: IConfiguration): void {
    this._configuration$.next(config);
  }

  getLayoutViewDataCache(): LayoutViewData {
    if (this.platformService.isServer) {
      return this.defaultViewData;
    }

    const layoutViewDataJson = localStorage.getItem(
      LAYOUT_VIEW_DATA_STORAGE_KEY
    );

    if (!layoutViewDataJson) {
      return this.defaultViewData;
    }

    return JSON.parse(layoutViewDataJson);
  }

  setLayoutViewDataCache(layoutViewData: LayoutViewData): void {
    if (this.platformService.isServer) return;

    localStorage.setItem(
      LAYOUT_VIEW_DATA_STORAGE_KEY,
      JSON.stringify(layoutViewData)
    );
  }
}
