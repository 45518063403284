<div
  class="col-x-center grid grid-gutter-20 grid-nowrap align-items-center py-40"
>
  <div
    class="grid-item grid justify-content-center"
    [ngSwitch]="errorType"
  >
    <div
      *ngSwitchCase="ModalErrorType.Error"
      class="icon icon-warning icon-80"
    ></div>
    <div
      *ngSwitchCase="ModalErrorType.Info"
      class="icon icon-info icon-80"
    ></div>
    <div
      *ngSwitchCase="ModalErrorType.Success"
      class="icon icon-check-mark icon-80"
    ></div>
  </div>
  <div
    class="grid-item"
    *ngIf="title || content"
  >
    <div
      class="typography typography-h4 typography-bold grey-900"
      *ngIf="title"
      [innerHTML]="title"
    ></div>
    <div
      class="typography typography-headline grey-900"
      *ngIf="content"
      [innerHTML]="content"
    ></div>
  </div>
</div>
