import { Injectable, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { SearcherRepositoryService } from './searcher-repository.service';
import {
  combineLatest,
  debounceTime,
  filter,
  map,
  of,
  Subject,
  switchMap,
} from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';

const DelayBetweenKeyDown = 300;

@Injectable()
export class SearcherFormService implements OnDestroy {
  constructor(private repository: SearcherRepositoryService) {}

  control = new FormControl();

  private ngUnsubscribe$ = new Subject<void>();

  /**
   * Emit when entered min <SearchStringLengthMin> keys.
   * Delay setting <DelayBetweenKeyDown>.
   * @private
   */
  onChangeControl(): void {
    this.control.valueChanges
      .pipe(
        map((query) => query.trim()),
        switchMap((query) =>
          combineLatest([this.repository.isSearchV2$, of(query)]).pipe(first())
        ),
        filter(([isV2, query]) => {
          const searchStringLengthMin = isV2 ? 2 : 3;
          return query.length >= searchStringLengthMin;
        }),
        debounceTime(DelayBetweenKeyDown),
        switchMap(([, query]) => this.repository.search(query)),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}
