import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ItemInComplexModalComponent } from './components/item-in-complex-modal/item-in-complex-modal.component';
import { ItemDuplicateModalComponent } from './components/item-duplicate-modal/item-duplicate-modal.component';
import { ModalService } from '../../services/modal/modal.service';
import { AddCartItemService } from './services/add-cart-item.service';
import { CartClient } from '@medindex-webapi';

@NgModule({
  declarations: [ItemInComplexModalComponent, ItemDuplicateModalComponent],
  imports: [CommonModule],
  providers: [CartClient, AddCartItemService, ModalService],
})
export class AddCartItemModule {}
