import { Injectable } from '@angular/core';
import { ItemNavigation } from '@shared/components/mobile-bottom-navigation/interfaces/bottom-navigation.interfaces';
import { HelixRoutes } from '@shared/enums/routes';

@Injectable()
export class MobileBottomNavigationDatasourceService {
  itemsNavigation(): ItemNavigation[] {
    return [
      {
        title: 'Сдать анализы',
        icon: 'icon-tube',
        link: `/${HelixRoutes.Catalog}`,
      },
      {
        title: 'Прием врачей',
        icon: 'icon-stethoscope',
        link: `/${HelixRoutes.Catalog}/vrachebnye-uslugi`,
      },
      {
        title: 'Выезд на дом',
        icon: 'icon-ambulance-car',
        link: `/${HelixRoutes.MobileService}`,
      },
    ];
  }
}
