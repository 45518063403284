import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CityRepositoryService } from '../../services/city-repository.service';

@Component({
  selector: 'app-city-tags',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './city-tags.component.html',
  styleUrls: ['./city-tags.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CityTagsComponent {
  constructor(public repository: CityRepositoryService) {}

  scrollToTag(tag: string): void {
    this.repository.setTag(tag);
    this.repository.setScrollEvent();
  }
}
