export enum TabIds {
  Catalog = 'catalogSearchResult',
  Promotion = 'promotionSearchResult',
  Knowledge = 'knowledgeBaseSearchResult',
  Address = 'addressSearchResult',
}

export enum TabTitles {
  Catalog = 'Анализы',
  Promotion = 'Акции',
  Knowledge = 'Helixbook',
  Address = 'Адреса',
}
