import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CountryTab } from '../../enums/coutry-tab.enum';
import { CityRepositoryService } from '../../services/city-repository.service';

@Component({
  selector: 'app-country-select',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './country-select.component.html',
  styleUrls: ['./country-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CountrySelectComponent {
  constructor(public repository: CityRepositoryService) {}

  CountryTab = CountryTab;
}
