import { HttpErrorResponse } from '@angular/common/http';
import { HttpResponseStatuses } from 'src/app/shared/enums/http-statuses';
import {
  ApiSuccessResponse,
  ApiErrorResponse,
} from '../types/api-response.type';
import { ErrorMessages } from '../types/error-messages.enum';

export const processSuccessApiResponse = <T>(
  response: T
): ApiSuccessResponse<T> => {
  return {
    success: true,
    payload: response,
  };
};

export const isDate = (value: any): boolean => {
  return value instanceof Date;
};

export const processErrorApiResponse = (
  response: HttpErrorResponse
): ApiErrorResponse => {
  return {
    success: false,
    errorStatus: response.status || HttpResponseStatuses.InternalServerError,
    errorMessage: response.message || ErrorMessages.Common,
    error: response.error,
  };
};

/**
 * Parsing object to URL params
 * Input:
 * {
 *  a: 1,
 *  b: { param1: null, param2: 2, param3: [1, 2, 3] },
 *  c: null,
 *  d: [1, 2, 3],
 *  e: new Date()
 * }
 * Output:
 * {
 *   a: 1,
 *   b.param2: 2
 *   b.param3: [1, 2, 3]
 *   d: [1, 2, 3],
 *   e: e.toISOString()
 * }
 */
export const parseObjectToUrlParams = (body: {
  [key: string]: any;
}): { [key: string]: string } => {
  const params: { [key: string]: string } = {};
  const parser = (
    parseObject: { [key: string]: any },
    prevKey: string | null = null
  ) => {
    Object.keys(parseObject).forEach((key) => {
      if (parseObject[key] !== null) {
        if (
          typeof parseObject[key] === 'object' &&
          !isDate(parseObject[key]) &&
          !Array.isArray(parseObject[key])
        ) {
          parser(parseObject[key], key);
        } else {
          if (isDate(parseObject[key])) {
            parseObject[key] = parseObject[key].toISOString();
          }
          params[prevKey ? `${prevKey}.${key}` : key] = parseObject[key];
        }
      }
    });
  };

  parser(body);

  return params;
};

export const clearPhone = (phone: string): string => {
  return phone.replace(/[-_)(+\s]/g, '');
};

export const firstCharToLowerCase = (string: string): string => {
  return string.charAt(0).toLowerCase() + string.slice(1);
};

export const clearHtmlTags = (html: string): string => {
  return html.replace(/<[^>]*>/g, '');
};
