import {
  HTTP_INTERCEPTORS,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable, NgModule } from '@angular/core';
import {
  StateKey,
  TransferState,
  makeStateKey,
} from '@angular/platform-browser';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { PlatformService } from '@shared/services/platform.service';

@Injectable()
export class HttpCacheSkipInterceptor implements HttpInterceptor {
  /** API, которые мы не хотим брать из TransferState кэша, пришедшего с SSR. */
  private readonly apisToSkipCache: string[] = ['api/cart/'];

  constructor(
    private transferState: TransferState,
    private platform: PlatformService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    if (this.platform.isBrowser && this.shouldSkipCacheFor(req.url)) {
      this.getCacheKeysToRemove().forEach((cacheKey) =>
        this.transferState.remove(cacheKey)
      );
    }
    return next.handle(req);
  }

  private shouldSkipCacheFor(it: string): boolean {
    return this.apisToSkipCache.some((apiToSkip) => it.includes(apiToSkip));
  }

  private getCacheKeysToRemove(): StateKey<unknown>[] {
    // eslint-disable-next-line @typescript-eslint/dot-notation
    return Object.keys(this.transferState['store'])
      .filter((key) => this.shouldSkipCacheFor(key))
      .map((key) => makeStateKey<unknown>(key));
  }
}

@NgModule({
  providers: [
    TransferState,
    HttpCacheSkipInterceptor,
    {
      provide: HTTP_INTERCEPTORS,
      useExisting: HttpCacheSkipInterceptor,
      multi: true,
    },
  ],
})
export class TransferHttpCacheSkipModule {}

@NgModule({
  // порядок импорта важен
  imports: [TransferHttpCacheSkipModule, TransferHttpCacheModule],
})
export class TransferHttpCacheWithSkippingModule {}
