<div
  class="section section-border-bottom affix-top affix z-index"
  *ngIf="visuallyImpairedService.stateVI$ | async as stateVI"
>
  <div class="section-content py-28 overflow-hidden">
    <div
      class="grid align-items-center grid-sm-reverse grid-gutter-64 justify-content-between"
    >
      <div class="grid grid-sm-12 grid-item grid-gutter-64">
        <app-visually-impaired-layout
          class="grid-item"
          [title]="'Размер шрифта:'"
          [visuals]="fontSizesSet"
          [selectedFontSize]="stateVI.fontSize"
          (changeState)="setStateVI($event)"
        ></app-visually-impaired-layout>

        <app-visually-impaired-layout
          class="grid-item"
          [title]="'Цветовая схема:'"
          [visuals]="themesSet"
          (changeState)="setStateVI($event)"
        ></app-visually-impaired-layout>
      </div>

      <div class="grid grid-sm-12 grid-item full-width-sm">
        <button class="button button-s full-width-sm" (click)="switchOffVI()">
          Обычная версия
        </button>
      </div>
    </div>
  </div>
</div>
